@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");
html {
  scroll-behavior: smooth;
}
:root {
  --h-height: 70px;
  --f-height: 112px;

  @media (max-width: 991.98px) {
    --h-height: 60px;
    --f-height: 70px;
  }
  @media (max-width: 767.98px) {
    --f-height: 60px;
  }
  @media (max-width: 575.98px) {
    --h-height: 60px;
    --f-height: 60px;
  }
}
:root,
:root[data-theme="light"] {
  --bg-header: #fff;
  --bg-header-active: #f7f7f7;
  --main-color: #222;
  --main-color-btn: #222;
  --main-color-text: #000;
  --main-color-text-title: #000;
  --main-color-text-subtitle: #000;
  --main-color-text-revers: #fff;
  --bs-secondary-color: #444;
  --bs-progress-bg-new: #e9e9e9;
  --color-light-gray: #d1d1d1;
  --bg-main: #fff;
  --bg-btn: #dadada;
  --bg-second: #f7f7f7;
  --bg-second-hover: #eeeeee;
  --bg-footer: #1c1c1c;
  --bg-checkbox: transparent;
  --bs-border-color: #999;
  --hr-color: #dbdbdb;
  --gray: #727272;
  --light-gray: #1e1e1e;
  --divider: #121212;
  --bg-catalog-menu: #fff;
  --modal-close: #ddd;
  --bg-card: #fff;
  --dropdown-menu: #fff;
  --dropdown-menu-hover: #eee;
  --box-shadow-top: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 255, 255) 100%
  );
  --box-shadow-bottom: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 255, 255) 100%
  );
}

:root[data-theme="dark"] {
  --bg-header: #222;
  --bg-header-active: #333;
  --main-color: #222;
  --main-color-btn: #222;
  --main-color-text: #fff;
  --main-color-text-title: #fff;
  --main-color-text-subtitle: #fff;
  --main-color-text-revers: #111;
  --bs-secondary-color: #777;
  --bs-progress-bg-new: #333;
  --color-light-gray: #444;
  --bg-main: #131313;
  --bg-btn: #333;
  --bg-second: #222;
  --bg-second-hover: #2e2e2e;
  --bg-footer: #222;
  --bg-checkbox: #2e2e2e;
  --hr-color: #2b2831;
  --gray: #727272;
  --light-gray: #555;
  --divider: #121212;
  --bg-catalog-menu: #222;
  --modal-close: invert(1) grayscale(100%) brightness(200%);
  --bs-border-color: #222;
  --bg-card: #222;
  --dropdown-menu: #202020;
  --dropdown-menu-hover: #292929;
  --box-shadow-top: linear-gradient(
    0deg,
    rgba(19, 19, 19, 0) 0%,
    rgb(19, 19, 19) 100%
  );
  --box-shadow-bottom: linear-gradient(
    rgba(19, 19, 19, 0) 0%,
    rgb(19, 19, 19) 100%
  );
}
$radius: 1.25em;
$radius-sm: 0.9em;
$red: #e01e00;
$orange: #ff9d17;
$green: #00ab55;
$yellow: #fef8be;
$black: #212b36;
$dark: #3f3f3f;
$dark-gray: #ddd;
$gray: #c2c2c2;
$grayish: #ebebeb;
$transition: 200ms ease-in;

$bp-xxs: 440px;
$bp-xs: 510px;
$bp-sm: 580px;
$bp-md: 770px;
$bp-lg: 991px;
$bp-xl: 1199px;
$bp-xxl: 1399px;

/* mixins */
@mixin flex($dir: row, $x: center, $y: center, $wrap: nowrap) {
  display: flex;
  flex-direction: $dir;
  justify-content: $x;
  align-items: $y;
  flex-wrap: $wrap;
}
@mixin no-appearance {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  outline: none;
  padding: 0px;
  margin: 0px;
}
@mixin safariFix {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  isolation: isolate;
  transform: translateZ(0);
}
@mixin absBG($z: 1, $top: 0%, $h: 100%) {
  position: absolute;
  top: $top;
  left: 0px;
  width: 100%;
  height: $h;
  z-index: $z;
}

@mixin clamp($count, $line, $strict: false) {
  display: -webkit-box;
  -webkit-line-clamp: $count;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: $line;
  @if $strict {
    height: $line * $count;
  } @else {
    max-height: $line * $count;
  }
}
@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin list {
  list-style: none;
  padding-left: 0px;
}
@mixin placeholder($color, $fw: 400) {
  &:-moz-placeholder,
  &::-moz-placeholder,
  &::-webkit-input-placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    color: $color;
    font-weight: $fw;
  }
  &::placeholder {
    color: $color;
    font-weight: $fw;
  }
}
@mixin roundedImg($w, $rad: 50%, $fit: cover) {
  width: $w;
  height: $w;
  border-radius: $rad;
  object-fit: $fit;
}

@mixin shadow {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
}
@mixin shadowF {
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.06));
}
@mixin lightShadow {
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.04);
}
@mixin scrollCustomized {
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--bg-btn);
    border-radius: 7px;
  }
  &::-webkit-scrollbar-button:vertical:start:decrement,
  &::-webkit-scrollbar-button:vertical:end:increment,
  &::-webkit-scrollbar-button:horizontal:start:decrement,
  &::-webkit-scrollbar-button:horizontal:end:increment {
    display: none;
  }
}
@mixin scrollCustomizedHide {
  &::-webkit-scrollbar {
    display: block;
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-button:vertical:start:decrement,
  &::-webkit-scrollbar-button:vertical:end:increment,
  &::-webkit-scrollbar-button:horizontal:start:decrement,
  &::-webkit-scrollbar-button:horizontal:end:increment {
    display: none;
  }
}
/* Typography */
a,
a:hover,
a:focus,
a:active {
  color: inherit;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
span,
a,
button,
form,
fieldset,
legend,
select,
input,
textarea,
div,
blockquote,
cite,
figure,
figcaption,
table,
ul,
ol,
dl,
li,
address {
  line-height: 135%;
  margin-bottom: 0px;
}
label {
  cursor: pointer;
}
button {
  user-select: none;
}
h1,
h2,
h3 {
  color: var(--main-color-text-title);
}
.rotateY-180 {
  transform: rotateY(180deg);
}
.flex-1 {
  flex: 1;
}
.border-top {
  border-color: var(--bg-btn) !important;
}
.w {
  &-xs-100 {
    @media (max-width: $bp-sm) {
      width: 100% !important;
    }
  }
  &-md-100 {
    @media (max-width: $bp-lg) {
      width: 100% !important;
    }
  }
  &-fit {
    width: fit-content;
  }
  &-auto {
    width: auto;
  }
  &-50p {
    width: 50px !important;
  }
}
hr {
  background-color: var(--hr-color);
  height: 0.5px;
  width: 100%;
  border: none;
  opacity: 1;
}

h1,
h2 {
  font-size: 1.75em;
  font-weight: 600;
  margin-bottom: 1em;
  @media (max-width: $bp-sm) {
    font-size: 1.35em;
  }
}
h3 {
  font-size: 2.25em;
  font-weight: 400;
  margin-bottom: 0.5em;
  @media (max-width: $bp-sm) {
    font-size: 2em;
  }
}
h4 {
  font-size: 1.875em;
  font-weight: 400;
  margin-bottom: 0.5em;
  @media (max-width: $bp-md) {
    font-size: 1.7em;
  }
  @media (max-width: $bp-sm) {
    font-size: 1.5em;
  }
}
h5 {
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 0.5em;
  @media (max-width: $bp-sm) {
    font-size: 1.25em;
  }
}
h6 {
  font-size: 1.125em;
  font-weight: 600;
  margin-bottom: 0.5em;
  @media (max-width: $bp-md) {
    font-size: 1em;
  }
}
.fs {
  &-06 {
    font-size: 0.6em;
  }
  &-07 {
    font-size: 0.7em;
  }
  &-08 {
    font-size: 0.8em;
  }
  &-09 {
    font-size: 0.9em;
  }
  &-10 {
    font-size: 1em;
  }
  &-11 {
    font-size: 1.1em;
  }
  &-12 {
    font-size: 1.2em;
  }
  &-13 {
    font-size: 1.3em;
  }
  &-14 {
    font-size: 1.4em;
  }
  &-15 {
    font-size: 1.5em;
  }
  &-16 {
    font-size: 1.6em;
  }
  &-17 {
    font-size: 1.7em;
  }
  &-18 {
    font-size: 1.8em;
  }
  &-19 {
    font-size: 1.9em;
  }
  &-20 {
    font-size: 2em;
  }
  &-22 {
    font-size: 2.2em;
  }
  &-25 {
    font-size: 2.5em;
  }
  &-30 {
    font-size: 3em;
  }
  &-35 {
    font-size: 3.5em;
  }
  &-40 {
    font-size: 4em;
  }
}
.fw {
  &-3 {
    font-weight: 300;
  }
  &-4 {
    font-weight: 400;
  }
  &-5 {
    font-weight: 500;
  }
  &-6 {
    font-weight: 600;
  }
  &-7 {
    font-weight: 700;
  }
  &-8 {
    font-weight: 800;
  }
  &-9 {
    font-weight: 900;
  }
}
.white {
  color: #fff;
}
.black {
  color: $black;
}
.dark-gray {
  color: $dark;
}
.gray {
  color: var(--light-gray);
}
.red {
  color: $red;
}
.orange {
  color: $orange;
}
.green {
  color: $green;
}
.main-color {
  color: var(--main-color);
}
.main-color-60 {
  color: var(--main-color);
}
.bg-main-01 {
  background-color: var(--main-color);
}
.bg-gray {
  background-color: $grayish;
}

/* Form elements */
input,
textarea,
.input {
  @include no-appearance;
  background-color: var(--bg-main);
  border: 1px solid var(--bg-btn);
  color: var(--main-color-text);
  border-radius: $radius * 0.5;
  padding: 0.7em 1.2em;
  width: 100%;
  outline: none;
  display: block;
  transition: border $transition;
  @include placeholder($gray, 400);

  &:hover,
  &:focus,
  &:active {
    border: 1px solid var(--gray);
  }
}
.input-sm > label {
  margin-bottom: 4px !important;
  font-size: 13px !important;
}
.input-sm input,
.input-sm textarea {
  padding: 0.7em 1em !important;
  font-size: 14px !important;
}
// @media not all and (min-resolution: 0.001dpcm) {
//   @supports (-webkit-appearance: none) {
//     input[type="date"],
//     input[type="time"],
//     input[type="datetime-local"] {
//       /* Переопределение стилей для Safari */
//       padding: 12px !important;
//       background-color: #f9f9f9;
//       -webkit-appearance: none; /* Отключаем стандартный вид Safari */
//     }
//   }
// }
.error {
  input {
    border-color: $red !important;
  }
}
input {
  &[type="checkbox"] {
    @include no-appearance;
    width: $radius;
    height: $radius;
    // border: none !important;
    background-color: var(--bg-checkbox);
    border-color: var(--bs-border-color);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border-radius: calc($radius * 0.25);
    box-shadow: none;
    cursor: pointer;
    &:focus,
    &:active,
    &:hover,
    &.active {
      box-shadow: none !important;
      outline: none !important;
      border-color: var(--main-color);
    }
    &:checked {
      // background-image: url(imgs/mark.svg);
      background-color: var(--main-color);
      border: none !important;
    }
    &[role="switch"] {
      cursor: pointer;
      width: 2.5em;
      height: 1.3em;
      background-color: var(--light-gray);
      border: none;
      border-radius: $radius;
      background-image: none;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0.25em;
        top: 0.15em;
        height: 0.95em;
        width: 0.95em;
        background-color: var(--bg-second);
        border-radius: 50%;
        transition: left $transition;
      }
      &:checked {
        background-color: $green;
        &::before {
          left: 1.3em;
        }
      }
    }
  }
  &[type="radio"] {
    @include no-appearance;
    width: $radius;
    height: $radius;
    width: 20px !important;
    height: 20px !important;
    border: 1px solid var(--main-color);
    background-color: var(--bg-second);
    border-radius: 50%;
    box-shadow: none;
    cursor: pointer;
    transition: background-color $transition;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 4px;
      left: 4px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: var(--main-color);
      transition: background-color $transition;
      opacity: 0;
    }
    &:checked::before {
      opacity: 1;
    }
  }
  &[type="number"] {
    appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      @include no-appearance();
      margin: 0;
    }
  }
}
.btn-group {
  input {
    display: none !important;
  }
}
.password {
  position: relative;
  input {
    font-size: inherit;
    font-weight: inherit;
    position: relative;
    z-index: 1;
  }
  button {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 5;
    color: var(--light-gray);
    height: 100%;
    width: 2em;
    font-size: 1.6em;
    @include flex();
    svg {
      path {
        stroke-width: 1.25px;
      }
    }
  }
}

.inputGroup {
  @include list();
  @include flex(row, flex-start, center, nowrap);
  width: fit-content;
  background-color: var(--bg-second);
  padding: 0.5em;
  border-radius: 1em;
  @media (max-width: $bp-md) {
    width: 100%;
  }
  label {
    cursor: pointer;
    width: 100%;
    .text {
      width: 100%;
      background-color: transparent;
      border-radius: 0.5em;
      font-weight: 500;
      padding: 0.7em 1em;
      font-size: 0.9em;
      transition: background-color $transition;
      text-align: center;
    }
    input {
      display: none;
      &:checked + .text {
        background-color: var(--bg-main);
        font-weight: 600;
        @include shadow();
      }
    }
  }
  li {
    position: relative;
    @media (max-width: $bp-md) {
      flex-grow: 1;
    }
    &:not(:first-child) {
      margin-left: 0.5em;
      padding-left: 0.5em;
      &::before {
        content: "";
        position: absolute;
        top: 10%;
        left: 0%;
        height: 80%;
        width: 0.5px;
        background-color: var(--bg-btn);
      }
    }
    &:first-child {
      label {
        .text {
          border-top-left-radius: 0.5em;
          border-bottom-left-radius: 0.5em;
        }
      }
    }
    &:last-child {
      label {
        .text {
          border-top-right-radius: 0.5em;
          border-bottom-right-radius: 0.5em;
        }
      }
    }
  }
}
.countInput {
  width: fit-content;
  svg {
    color: var(--main-color-text);
  }
  min-height: 40px;
  padding: 0px;
  @include flex();
  @media (max-width: $bp-xl) {
    font-size: 1.15em;
  }
  &.disabled {
    pointer-events: none;
  }
  @media (max-width: $bp-sm) {
    padding: 0.35em 0;
    font-size: 1em !important;
  }
  input {
    padding: 0px;
    height: $radius * 1.5;
    width: $radius * 2;
    text-align: center;
    border-radius: $radius;
    @include lightShadow();
    margin: 0px $radius * 0.25;
    @media (max-width: $bp-md) {
      width: 60px;
    }
    @media (max-width: $bp-sm) {
      width: 40px;
    }
  }
  button {
    padding: 0.3em;
    background-color: var(--bg-second);
    border: 1px solid var(--bg-btn);
    border-radius: $radius;
    color: var(--light-gray);
    height: $radius * 1.5 !important;
    width: $radius * 1.5 !important;
    @include flex();
    @include lightShadow();
    transition: $transition;
    transition-property: color, background-color, border;
    svg {
      font-size: 0.8em;
    }
    &:hover,
    &:focus {
      color: var(--main-color);
    }
    &:active {
      color: #fff;
      background-color: var(--main-color);
      border: 1px solid var(--main-color);
    }
  }
}
.promoCode {
  @include flex();
  input {
    flex: 1;
    border-radius: $radius * 0.5 0px 0px $radius * 0.5;
  }
  .btn-primary {
    border-radius: 0px $radius * 0.5 $radius * 0.5 0px;
    padding: 0.75em;
  }
  .clear {
    color: $dark;
    font-size: 1.5em;
    margin-left: $radius * 0.25;
  }
}

button {
  background: none;
  border: none;
  padding: unset;
  display: block;
  font-family: inherit;
  outline: none;
  color: inherit;
}
.btn {
  user-select: none;
  @include flex(row, center, center, nowrap);
  min-height: 40px;
  padding: 0px 1.2em;
  transition: all 0.2s;
  text-align: center;
  font-weight: 500;
  width: fit-content;
  border-radius: $radius * 1.5;
  &-primary {
    @extend .btn;
    color: #fff;
    background-color: var(--main-color-btn);
    border: 1px solid var(--main-color-btn);
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: #fff !important;
      opacity: 0.8;
      background-color: var(--main-color-btn) !important;
      border: 1px solid var(--main-color-btn) !important;
    }
  }
  &-10 {
    @extend .btn;
    color: var(--main-color);
    background-color: var(--bg-main);
    border: 1px solid var(--main-color);
    &:hover,
    &:focus,
    &:active,
    &.active,
    &:not(.btn-check) + .btn:active {
      color: #fff !important;
      background-color: var(--main-color) !important;
    }
  }
  &-2 {
    @extend .btn;
    color: #fff;
    background-color: $red;
    &:hover,
    &:focus,
    &:active,
    &.active,
    &:not(.btn-check) + .btn:active {
      color: #fff;
      background-color: darken($color: $red, $amount: 5%);
    }
  }
  &-secondary {
    @extend .btn;
    color: #fff;
    background-color: #202020;
    border: 1px solid var(--main-color);
    font-weight: 600;
    &:hover,
    &:focus,
    &:active,
    &.active,
    &:not(.btn-check) + .btn:active {
      color: #fff;
      background-color: var(--main-color-outline);
    }
  }
  &-30 {
    @extend .btn;
    color: $black;
    background-color: var(--bg-second);
    border: 1px solid $black;
    font-weight: 600;
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: #fff;
      background-color: $black;
    }
  }
  &-4,
  &-white {
    @extend .btn;
    color: var(--main-color-text);
    border: var(--bg-second);
    background-color: var(--bg-second);
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: var(--main-color);
      background-color: var(--bg-second);
      border: var(--bg-second);
    }
  }
  &-40-light {
    @extend .btn;
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff;
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: #fff;
      background-color: rgba($color: #fff, $alpha: 0.1);
      border: 1px solid #fff;
    }
  }
  &-40 {
    @extend .btn;
    color: var(--main-color);
    background-color: transparent;
    border: 1px solid var(--main-color);
    &:hover,
    &:focus,
    &:active,
    &.active {
      opacity: 0.9;
      color: var(--main-color);
      background-color: transparent;
      border: 1px solid var(--main-color);
    }
  }
  &-green {
    @extend .btn;
    color: $green;
    border: 1px solid transparent;
    background-color: rgba($color: $green, $alpha: 0.1);
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: #fff;
      background-color: $green;
      border: 1px solid $green;
    }
  }
  &-deepgreen {
    @extend .btn;
    color: #fff;
    background-color: $green;
    border: 1px solid $green;
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: #fff;
      background-color: darken($color: $green, $amount: 10%);
      border: 1px solid darken($color: $green, $amount: 10%);
    }
  }

  /* светло-голубая кнопка */
  &-light {
    @extend .btn;
    color: var(--main-color);
    background-color: var(--main-color-outline);
    border: none;
    &:hover,
    &:focus,
    &:active,
    &.active {
      opacity: 0.9 !important;
      color: var(--main-color) !important;
      background-color: var(--main-color-outline) !important;
      border: none !important;
    }
  }
  /* белая кнопка c тенью */
  &-8 {
    @extend .btn;
    background-color: var(--bg-btn);
    @include lightShadow();
    color: var(--main-color-text);
    @media (max-width: $bp-lg) {
      background-color: var(--bg-btn);
    }
    svg {
      color: var(--main-color);
      transition: color $transition;
    }
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: var(--main-color);
      color: #fff;
      svg {
        color: #fff;
      }
    }
  }
  /* серая кнопка */
  &-9 {
    @extend .btn;
    color: #fff;
    background-color: var(--light-gray);
    border: 1px solid var(--light-gray);
    &:hover,
    &:focus,
    &:active,
    &.active {
      opacity: 0.8;
    }
    svg {
      stroke-width: 1px;
    }
  }
  /* кнопка c серой обводкой */
  &-90 {
    @extend .btn;
    color: $black;
    background-color: var(--bg-second);
    border: 1px solid var(--light-gray);
    font-weight: 600;
    &:hover,
    &:focus {
      color: $black;
      background-color: var(--bg-second);
      border: 1px solid var(--light-gray);
      @include shadow();
    }
    &:active,
    &.active {
      color: #fff;
      background-color: var(--main-color);
      border: 1px solid var(--main-color);
    }
  }
  /* светло-серая кнопка */
  &-99 {
    @extend .btn;
    color: $dark;
    background-color: var(--bg-btn);
    border: 1px solid var(--bg-btn);
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: #fff;
      background-color: $dark;
      border: 1px solid $dark-gray;
    }
  }
  /* orange */
  &-orange {
    @extend .btn;
    color: $orange;
    background-color: rgba($color: $orange, $alpha: 0.1);
    border: 1px solid transparent;
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: #fff;
      background-color: $orange;
      border: 1px solid $orange;
    }
  }
  /* red */
  &-red {
    @extend .btn;
    color: $red;
    background-color: rgba($color: $red, $alpha: 0.1);
    border: 1px solid transparent;
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: #fff;
      background-color: $red;
      border: 1px solid $red;
    }
  }
  &[disabled],
  &-disabled {
    background-color: var(--bg-btn);
    border: 1px solid var(--bg-btn);
    color: var(--bs-secondary-color);
    cursor: pointer;
    pointer-events: none;
  }

  &-fav {
    font-size: 1.75em;
    @include flex();
    @media (max-width: $bp-xl) {
      right: 0.5em;
    }
    @media (max-width: $bp-md) {
      bottom: 0em;
      right: 0em;
    }
    svg {
      stroke-width: 1px;
      stroke: $dark-gray;
      fill: transparent;
      transition: $transition;
      transition-property: stroke, fill;
    }
    &.active {
      svg {
        stroke: var(--main-color);
        fill: var(--main-color);
      }
    }
  }
  &.loading {
    position: relative;
    color: transparent !important;
    & > * {
      opacity: 0;
    }
    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      // top: 33%;
      // left: 50%;
      width: 1.2em;
      height: 1.2em;
      background: url(imgs/spin.png) no-repeat;
      background-size: contain;
      opacity: 1;
      animation: spin 1s linear 0s infinite;
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }

  &-group {
    padding: 2px;
    border: 1px solid var(--bg-btn);
    border-radius: 50px;
    .btn-primary,
    .btn-primary:hover {
      color: inherit !important;
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }
  &-group > :not(.btn-check:first-child) + .btn,
  &-group > :not(.btn-check:first-child) + .btn-primary {
    border-radius: 50px;
    background-color: transparent;
    border-color: transparent;
  }
  &-group-sm .btn {
    min-height: 35px !important;
  }
  &-group > .btn:not(:last-child):not(.dropdown-toggle),
  &-group > .btn-primary:not(:last-child):not(.dropdown-toggle),
  &-group > .btn:not(:last-child):not(.dropdown-toggle):hover,
  &-group > .btn-primary:not(:last-child):not(.dropdown-toggle):hover {
    border-radius: 50px;
    background-color: transparent;
    border-color: transparent;
  }
  &-check:checked + .btn,
  &-check:checked + .btn-primary {
    background-color: var(--main-color-btn) !important;
    border: 1px solid var(--main-color-btn) !important;
    color: #fff !important;
  }
}

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.loading-indicator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  &.mini {
    position: relative !important;
    height: auto !important;
  }
}

.loading-indicator.active {
  opacity: 1;
}

.loading-indicator.inactive {
  opacity: 0;
}
.delivery-loader {
  position: absolute;
  top: 23%;
  left: 0px;
  right: 0px;
  margin: 0px auto;
  text-align: center;
  justify-content: center;
  display: flex;
}
.spinner {
  display: block;
  margin: 0px auto;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 3px solid var(--main-color);
  border-color: var(--main-color) transparent var(--main-color) transparent;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

select {
  @include no-appearance();
  border: none;
  background-image: url(imgs/chevron.svg);
  background-repeat: no-repeat;
  padding-right: 1em;
  background-position: right center;
  background-size: 0.75em;
}
.select {
  user-select: none;
  position: relative;
  min-width: auto;
  &-button {
    width: 100%;
    position: relative;
    z-index: 10;
    @include flex();
    border: 1px solid var(--main-color);
    border-radius: 1.5em;
    padding: 0.45em 0.75em;
    height: 100%;
    &.borderless {
      border: none;
    }
    &::after {
      content: unset;
    }
    &-value {
      width: calc(100% - 1.5em);
      @include flex(row, flex-start, center, nowrap);
      img {
        width: 1.5em;
        height: 1.5em;
        object-fit: contain;
        margin-right: 0.5em;
        &.round {
          border-radius: 50%;
          filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
        }
      }
      span {
        flex: 1;
        @include ellipsis();
        text-align: left;
      }
    }
    &-chevron {
      font-size: 1em;
      color: var(--main-color);
      margin-left: 0.25em;
    }
  }
  &-options {
    @include scrollCustomizedHide();
    @include list();
    min-width: 160px;
    width: 100%;
    @include shadow();
    border-radius: $radius * 0.5;
    border-color: var(--bg-second-hover);
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 330px;
    padding: 0px;
    @include safariFix();
    @media (max-width: $bp-lg) {
      min-width: fit-content;
    }
    &-item {
      display: block;
      width: 100%;
      padding: 0px !important;
      margin: 0px !important;
      &:not(:first-child) {
        border-top: 1px solid var(--bg-btn);
      }
    }
    label {
      position: relative;
      width: 100%;
      cursor: pointer;
      background-color: var(--bg-second);
      transition: background-color $transition;
      padding: 0.5em 1em;
      &.active {
        background-color: var(--main-color);
      }
      input {
        position: relative;
        opacity: 0;
        z-index: -1;
      }
      img {
        position: relative;
        z-index: 2;
        width: 1.5em;
        height: 1.5em;
        object-fit: contain;
        margin-right: 0.5em;
        &.round {
          border-radius: 50%;
          filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
        }
      }
    }
    &-label {
      position: relative;
      z-index: 2;
    }
  }
  &-label {
    font-size: 14px;
    position: relative;
    z-index: 2;
  }
}

.input-file {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: $radius;
  transition: border-color $transition;
  background-color: var(--bg-second);
  border: 1px solid var(--bg-btn);
  position: relative;
  &:hover,
  &:focus {
    cursor: pointer;
    border: 1px solid var(--light-gray);
  }
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 1px;
    height: 1px;
    top: 0px;
    left: 0px;
    padding: 0px;
  }
  svg {
    color: var(--main-color);
    font-size: 1.5em;
    stroke-width: 1.25px;
  }
  .ind {
    position: absolute;
    bottom: -0.25em;
    right: 0px;
    color: var(--main-color);
    font-weight: 600;
    line-height: 1em;
    font-size: 0.9em;
    &:empty {
      display: none;
    }
  }
}

/* Sliders */
.swiper {
  position: relative;
  overflow: hidden;
  &-pagination {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    @include flex(row, center, center, nowrap);
    &-bullet {
      display: block;
      opacity: 1;
      width: 0.5em;
      height: 0.5em;
      border-radius: 0.25em;
      margin: 0 0.75em !important;
      background-color: var(--main-color-outline);
      transition: width $transition;
      &-active {
        width: 1.8em;
        background-color: var(--main-color);
      }
    }
  }
  &-button {
    position: absolute;
    bottom: 10px;
    z-index: 100;
    font-size: 2.75em;
    width: 1em;
    height: 1em;
    @include flex();
    transform: scale(100%);
    transition: transform $transition;
    cursor: pointer;
    color: #fff;
    svg {
      stroke-width: 1px;
      fill: rgba($color: #fff, $alpha: 0.5);
    }
    &-prev {
      @extend .swiper-button;
      left: 10px;
    }
    &-next {
      @extend .swiper-button;
      right: 10px;
    }
    &-disabled {
      opacity: 0;
    }
    &-lock {
      display: none;
    }
  }
  &.paginated {
    padding-bottom: 1.75em;
    .swiper-button {
      bottom: 0.9em;
    }
  }
  &-arrow {
    @include flex();
    position: absolute;
    top: 50%;
    z-index: 10;
    font-size: 1.7em;
    color: var(--main-color-text);
    transform: translateY(-50%);
    transition: color $transition;
    @media (max-width: $bp-md) {
      display: none !important;
    }
    &[data-locked="locked"] {
      opacity: 0;
    }
    &.disabled,
    &.locked {
      opacity: 0;
    }
    &:hover,
    &:focus {
      color: var(--main-color);
    }
    &:active {
      color: var(--main-color);
    }
    svg {
      stroke-width: 1.25px;
    }
    &-prev {
      @extend .swiper-arrow;
      left: -1.25em;
    }
    &-next {
      @extend .swiper-arrow;
      right: -1.25em;
    }
  }
}

.notice {
  background-color: $yellow;
  border-radius: $radius;
  padding: 1.5em;
  p {
    color: #222;
    font-weight: 500;
  }
  position: relative;
  margin-top: 0.5em;
  margin-bottom: 2em;
  padding-right: 9em;
  @media (max-width: $bp-sm) {
    margin-bottom: 1em;
    padding: 1.25em;
    padding-right: 5em;
    border-radius: $radius * 0.5;
  }
  &::after {
    content: "";
    background: url(../assets/imgs/lightning.png) no-repeat center;
    background-size: contain;
    position: absolute;
    top: -0.5em;
    right: 0.5em;
    height: calc(100% + 1em);
    width: 6em;
    @media (max-width: $bp-sm) {
      right: -0.5em;
      width: 5em;
    }
  }
  &.paginated {
    .swiper-button {
      bottom: 0.9em;
    }
  }
}

.navTop {
  margin-bottom: 2em;
  color: var(--main-color);
  @include flex(row, flex-start, center, wrap);
  font-size: 1.125em;
  @media (max-width: $bp-lg) {
    margin-top: 1.5em;
  }
  @media (max-width: $bp-sm) {
    margin-top: 1em;
    margin-bottom: 1.5em;
  }
  &-back {
    @include flex();
    margin-right: 1em;
    svg {
      font-size: 1.35em;
      stroke-width: 1.25px;
      margin-right: 0.25em;
    }
  }
  &-breadcrumbs {
    @include flex(row, flex-start, center, wrap);
    @include list();
    li {
      &:not(:first-child) {
        &::before {
          content: "—";
          margin: 0em 0.5em;
        }
      }
    }
  }
}
.progress {
  background-color: var(--bs-progress-bg-new) !important;
}
.gifts {
  margin-bottom: 1.5em;
  ul {
    @include list();
    @include flex(row, flex-start, center, nowrap);
    li {
      flex-grow: 1;
      @include flex();
      &:not(:first-child) {
        margin-left: 10px;
      }
      .bar {
        flex: 1;
        height: 6px;
        border-radius: 40px;
        .progress-bar {
          border-radius: 40px !important;
          background-color: var(--main-color) !important;
        }
      }
      .icon {
        cursor: default;
        background-color: var(--light-gray);
        color: #fff;
        @include flex();
        margin-left: 10px;
        padding: 0.6em;
        border-radius: 50%;
        svg {
          font-size: $radius;
        }
        position: relative;
        &.active {
          cursor: pointer;
          background-color: var(--main-color);
          svg {
            animation: scale linear 1500ms infinite;
          }
        }
      }
      .text {
        position: absolute;
        bottom: 100%;
        z-index: 999;
        text-align: center;
        font-weight: bold;
        margin-bottom: 5px;
        &.active {
          color: var(--main-color);
        }
      }
    }
  }
}
@keyframes scale {
  10% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.breadcrumbs {
  margin-bottom: 2em;
  ul {
    @include list();
    @include flex(row, flex-start, center, wrap);
    font-size: 0.9em;
    li {
      color: var(--main-color-text);
      &:not(:last-child) {
        color: var(--main-color-text);
        margin-right: 1em;
        &::after {
          content: "●";
          color: var(--light-gray);
          margin-left: 1em;
          font-size: 0.75em;
        }
      }
    }
  }
}

body {
  margin: 0;
  font-family: "Open Sans", "Arial", "Tahoma", "Verdana";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 400;
  color: var(--main-color-text);
  background: var(--bg-main);
  overflow-x: hidden;
  @media (max-width: $bp-xl) {
    font-size: 14px;
  }
}

html.theme-font-robotocondensed body {
  font-family: "Roboto Condensed", "Arial", "Tahoma", "Verdana" !important;
}

header {
  height: var(--h-height);
  --pad: 40px;
  background-color: var(--bg-header) !important;
  position: sticky;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1050;
  padding: 0px var(--pad);
  @include shadow();
  width: calc(100% + var(--pad) * 2);
  margin-left: calc(var(--pad) * -1);
  @include flex(row, space-between, center, nowrap);
  @media (max-width: $bp-xl) {
    --pad: 30px;
  }
  @media (max-width: $bp-lg) {
    --pad: 0px;
    box-shadow: none;
  }
  // svg {
  //   stroke-width: 1px;
  // }
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ul {
    li > .dropdown > a {
      border: none !important;
      background-color: var(--bg-header-active) !important;
      min-height: 40px !important;
      padding: 0.5em 1em !important;
      font-size: 14px !important;
    }
    li > a {
      display: flex;
      position: relative;
      transition: all 200ms;
      height: var(--h-height);
      align-items: center;
      .cap {
        position: absolute;
        width: 34px;
        height: 34px;
        bottom: 30px;
        right: -9px;
        object-fit: contain;
        transition: all 300ms;
      }
      &:hover {
        color: var(--main-color);
        .cap {
          bottom: 33px;
        }
      }
      @media (max-width: $bp-xxl) {
        font-size: 13px !important;
      }
    }
    li .btn {
      @media (max-width: $bp-xxl) {
        font-size: 13px !important;
      }
    }
    @include list();
    @include flex();
    &.text-menu {
      li {
        &:not(:first-child) {
          margin-left: 2em;
          @media (max-width: $bp-xxl) {
            margin-left: 1.2em;
          }
        }
      }
    }
    &.icons-menu {
      & > li {
        &:not(:first-child) {
          margin-left: 1.25em;
          @media (max-width: $bp-xxl) {
            margin-left: 0.8em;
          }
        }
      }
    }
  }
  .logo {
    max-height: 50px;
    max-width: 200px;
    @media (max-width: $bp-xl) {
      max-height: 50px;
      max-width: 120px;
    }
    object-fit: contain;
  }
  .logo-name {
    font-size: 18px;
    font-weight: bold;
    @media (max-width: $bp-xl) {
      font-size: 16px;
    }
  }
  .phone {
    @include flex(row, flex-start, center, nowrap);
    color: var(--main-color-text);
    transition: color $transition;
    &:hover,
    &:focus,
    &:active {
      color: var(--main-color);
    }
    @media (max-width: $bp-lg) {
      display: none;
    }
  }
  .badge {
    font-size: 10px;
    background-color: var(--main-color);
    color: #fff;
    width: 1.6em;
    height: 1.6em;
    @include flex();
    top: 45px !important;
  }
  .btn-menu {
    color: var(--main-color-text);
    font-size: 2em;
    @include flex();
    transition: color $transition;
    &:hover,
    &:focus,
    &:active {
      color: var(--main-color);
    }
  }
}
.offcanvas-menu {
  width: 100% !important;
  .offcanvas-body {
    padding: calc(var(--h-height) + 2em) 1em calc(var(--f-height) + 2em);
    .menu-offer {
      width: 100%;
      border-radius: $radius;
      margin-bottom: 2em;
    }
    nav {
      @include lightShadow();
      border-radius: $radius;
      overflow: hidden;
      @include safariFix();
      ul {
        @include list();
        li {
          &:not(:first-child) {
            border-top: 1px solid var(--bg-btn);
          }
          a,
          button {
            @include flex(row, flex-start, center, nowrap);
            font-size: 1.5em;
            padding: 1em;
            @media (max-width: $bp-md) {
              font-size: 1.25em;
            }
            @media (max-width: $bp-sm) {
              font-size: 1.1em;
            }
            svg {
              font-size: 1.6em;
              color: var(--main-color);
              margin-right: 0.5em;
              @media (max-width: $bp-sm) {
                margin-right: 0.25em;
              }
            }
          }
        }
      }
    }
    .box {
      background-color: var(--bg-second);
      padding: 1em;
      border-radius: $radius * 0.25;
    }
  }
}
.appOffer {
  position: fixed;
  z-index: 100;
  right: 0px;
  top: calc(var(--h-height) * 2);
  background-color: $green;
  color: #fff;
  font-size: 2.5em;
  border-radius: 0.25em 0px 0px 0.25em;
  width: 1.15em;
  height: 1.5em;
  @include flex();
  @media (max-width: $bp-sm) {
    top: calc(var(--h-height) * 2.25);
    font-size: 2.5em;
    width: 1em;
    height: 1.25em;
  }
}
.offcanvas {
  background-color: var(--bg-main) !important;
  color: var(--main-color-text) !important;
}
path[fill="white"],
path[fill="#fff"] {
  fill: var(--bg-main) !important;
}
main {
  min-height: calc(100vh - var(--h-height));
  padding: 60px 0px;
  @media (max-width: $bp-lg) {
    min-height: 100vh;
    padding: var(--h-height) 0px calc(var(--f-height) + 30px);
  }
  @media (max-width: $bp-sm) {
    min-height: 100vh;
    padding: 0px 0px calc(var(--f-height) + 50px);
  }
}
.hello {
  min-height: 70vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 10px;
  align-items: center;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #fff !important;
  &.light {
    color: #222 !important;
  }
  &-box {
    max-width: 500px;
    h1 {
      font-size: 40px;
      font-weight: bold;
    }
    p {
      font-size: 18px;
    }
  }
}
.sec-1 {
  position: relative;
  overflow: hidden;
  .main-slider {
    overflow: visible;
    img {
      width: 100%;
      height: 370px;
      background-color: rgba(0, 0, 0, 0.08);
      object-fit: cover;
      border-radius: $radius;
      @media (max-width: $bp-xxl) {
        height: 320px;
      }
      @media (max-width: $bp-lg) {
        height: 275px;
      }
      @media (max-width: $bp-md) {
        height: 225px;
      }
      @media (max-width: $bp-sm) {
        height: 230px;
      }
      @media (max-width: $bp-xs) {
        height: 195px;
      }
      @media (max-width: $bp-xxs) {
        height: 160px;
      }
    }
    .big {
      height: 520px;
      @media (max-width: $bp-xxl) {
        height: 420px;
      }
      @media (max-width: $bp-lg) {
        height: 280px;
      }
      @media (max-width: $bp-md) {
        height: 220px;
      }
      @media (max-width: $bp-sm) {
        height: 200px;
      }
    }
  }
}

.swiper-stories {
  .swiper-slide {
    @media (max-width: $bp-md) {
      position: relative;
    }
    @media (max-width: $bp-sm) {
      --w: 21.5%;
    }
    width: var(--w);
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: var(--main-color-text) !important;
    top: 12%;
    bottom: 0px;
    height: 105%;
    width: 8%;
    &::after {
      font-size: 25px;
    }
  }
  .swiper-button-disabled {
    display: none;
  }
  .swiper-button-next {
    background: linear-gradient(
      270deg,
      var(--bg-main) 20%,
      transparent
    ) !important;
    right: 0 !important;
  }
  .swiper-button-prev {
    background: linear-gradient(
      90deg,
      var(--bg-main) 20%,
      transparent
    ) !important;
    left: 0 !important;
  }
}

.story {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  img {
    display: block;
    margin: 0 auto 0px;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    object-fit: cover;
    background-color: var(--bg-main);
    padding: 2px;
    border: 3px solid var(--main-color);
  }

  figcaption {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-shadow: -1px 1px 10px black;
    padding: 1em;
    position: absolute;
    font-size: 18px;
    font-weight: 500;
    bottom: 4px;
    left: 4px;
    right: 4px;
    top: 4px;
    text-align: center;
  }
  &.square {
    img {
      border-radius: 20px;
    }
    figcaption {
      border-radius: 16px;
      text-align: left !important;
    }
  }

  &.rectangle {
    img {
      border-radius: 20px;
    }
    figcaption {
      border-radius: 20px;
      text-align: left !important;
    }
  }
  @media (max-width: $bp-lg) {
    figcaption {
      font-size: 0.8em;
      padding: 0.8em;
      text-align: center;
    }
  }
  &-big {
    img {
      display: block;
      width: 450px;
      height: 800px;
      object-fit: contain;
      @media (max-width: $bp-sm) {
        width: 100%;
        height: 100vh;
        border-radius: 0px;
      }
    }
  }
}
.story-items-middle {
  img {
    height: 170px;
    width: 170px;
    @media (max-width: $bp-xxl) {
      height: 150px !important;
      width: 150px !important;
    }
    @media (max-width: $bp-xl) {
      height: 130px !important;
      width: 130px !important;
    }
    @media (max-width: $bp-lg) {
      height: 110px !important;
      width: 110px !important;
    }
    @media (max-width: $bp-md) {
      height: 95px !important;
      width: 95px !important;
    }
    @media (max-width: $bp-sm) {
      height: 85px !important;
      width: 85px !important;
    }
    @media (max-width: $bp-xs) {
      height: 17vw !important;
      width: 17vw !important;
    }
    @media (max-width: $bp-xxs) {
      height: 20vw !important;
      width: 20vw !important;
    }
  }
  .rectangle {
    img {
      border-radius: 20px;
      height: 230px;
      width: 100%;

      @media (max-width: $bp-xxl) {
        height: 200px !important;
      }
      @media (max-width: $bp-xl) {
        height: 180px !important;
      }
      @media (max-width: $bp-lg) {
        height: 150px !important;
      }
      @media (max-width: $bp-md) {
        border-radius: 15px;
        height: 120px !important;
      }
      @media (max-width: $bp-sm) {
        border-radius: 15px;
        height: 120px !important;
      }
      @media (max-width: $bp-xs) {
        border-radius: 12px;
        height: 22vw !important;
      }
      @media (max-width: $bp-xxs) {
        border-radius: 12px;
        height: 26vw !important;
      }
    }
  }
}
.story-items-big {
  img {
    height: 240px;
    width: 240px;
    @media (max-width: $bp-xxl) {
      height: 210px !important;
      width: 210px !important;
    }
    @media (max-width: $bp-xl) {
      height: 175px !important;
      width: 175px !important;
    }
    @media (max-width: $bp-lg) {
      height: 130px !important;
      width: 130px !important;
    }
    @media (max-width: $bp-md) {
      height: 120px !important;
      width: 120px !important;
    }
    @media (max-width: $bp-sm) {
      height: 115px !important;
      width: 115px !important;
    }
    @media (max-width: $bp-xs) {
      height: 20vw !important;
      width: 20vw !important;
    }
    @media (max-width: $bp-xxs) {
      height: 20vw !important;
      width: 20vw !important;
    }
  }
  .rectangle {
    img {
      border-radius: 25px;
      height: 340px;
      width: 100%;

      @media (max-width: $bp-xxl) {
        height: 300px !important;
      }
      @media (max-width: $bp-xl) {
        height: 250px !important;
      }
      @media (max-width: $bp-lg) {
        height: 185px !important;
      }
      @media (max-width: $bp-md) {
        border-radius: 20px;
        height: 170px !important;
      }
      @media (max-width: $bp-sm) {
        border-radius: 20px;
        height: 160px !important;
      }
      @media (max-width: $bp-xs) {
        border-radius: 18px;
        height: 26vw !important;
      }
      @media (max-width: $bp-xxs) {
        border-radius: 18px;
        height: 26vw !important;
      }
    }
  }
}
.modal-dialog {
  @media (max-width: $bp-sm) {
    margin: 0px !important;
  }
}
.modal-content {
  background-color: var(--bg-main) !important;
  border-radius: 20px !important;
  @media (max-width: $bp-sm) {
    border-radius: 0px !important;
  }
}
.modal {
  backdrop-filter: blur(12px);
  background-color: rgb(0 0 0 / 10%);
}
.story-modal {
  backdrop-filter: blur(12px);
  background-color: rgb(0 0 0 / 10%);
  .modal {
    &-dialog {
      max-width: 450px;
      @media (max-width: $bp-sm) {
        max-width: 100%;
        margin: 0px !important;
      }
    }
    &-content {
      background-color: transparent;
      border: none;
      border-radius: unset;
      @media (max-width: $bp-sm) {
        max-width: 100%;
        height: 100vh;
      }
    }
    &-body {
      padding: 0px;
      position: relative;
      .swiper-stories-modal {
        position: unset;
        width: 450px;
        @media (max-width: $bp-sm) {
          width: 100%;
        }
        .swiper-button {
          bottom: 50%;
          transform: translateY(50%);
          font-size: 2em;
          @media (max-width: $bp-md) {
            display: none;
          }
          &::after {
            font-size: 20px;
          }
          // &-prev {
          //   left: -1.25em;
          // }
          // &-next {
          //   right: -1.25em;
          // }
        }
        .swiper-scrollbar {
          position: absolute;
          z-index: 100;
          top: 1em;
          left: 1em;
          width: calc(100% - 2em);
          height: 3px;
          background-color: rgba(0, 0, 0, 0.3);
          &-drag {
            height: 3px;
            background-color: #fff;
          }
        }
      }
      .close {
        z-index: 10;
        position: absolute;
        top: 0px;
        left: calc(100% + 0.25em);
        color: #fff;
        font-size: 2em;
        @include flex();
        transition: $transition;
        transition-property: color, background-color;
        background-color: transparent;
        border-radius: $radius * 0.15;
        @media (max-width: $bp-md) {
          left: unset;
          right: 0.5em;
          top: 1em;
        }
        &:hover,
        &:focus,
        &:active {
          color: $red;
          background-color: var(--bg-second);
        }
      }
    }
  }
}

.sec-3 {
  .box {
    background-color: var(--bg-second);
    padding: $radius;
    border-radius: $radius;
    @media (max-width: $bp-md) {
      padding: 0px 1em;
      border-radius: 0px;
      box-shadow: none;
    }
  }
}
.btn-view {
  position: relative;
  color: var(--light-gray);
  font-size: 1.5em;
  @include flex();
  @media (max-width: $bp-lg) {
    display: none;
  }
  svg {
    position: relative;
    z-index: 10;
  }
  img {
    position: absolute;
    bottom: 0px;
    right: -4.5em;
    z-index: -1;
  }
}
.category-card {
  position: relative;
  img {
    width: 100%;
    height: 15vw;
    object-fit: cover;
    border-radius: $radius;
    margin-bottom: $radius * 0.5;
    @media (max-width: $bp-xxl) {
      height: 18vw;
    }
    @media (max-width: $bp-lg) {
      height: 30vw;
    }
    @media (max-width: $bp-sm) {
      height: 38vw;
      border-radius: $radius * 0.5;
    }
  }
  figcaption {
    text-align: center;
    a {
      transition: color $transition;
      &:hover {
        color: var(--main-color);
      }
    }
  }
}

.sec-app {
  background-image: url(imgs/curve.svg), url(imgs/trapezoid.svg);
  background-repeat: no-repeat;
  background-position: 12% 50%, center bottom;
  background-size: 62% auto, 100% calc(100% - 10em);
  padding: 10em 4em 3em;
  position: relative;
  @media (max-width: $bp-xxl) {
    padding: 8em 3em 2em;
    background-position: 12% 22%, center bottom;
    background-size: 62% auto, 100% calc(100% - 5em);
  }
  @media (max-width: $bp-xl) {
    padding: 6em 3em 2em;
    background-position: 12% 0%, center bottom;
    background-size: 62% auto, 100% calc(100% - 1em);
  }
  @media (max-width: $bp-lg) {
    padding: 5em 2em 1.5em;
    background-position: 0% 0%, center bottom;
    background-size: 80% auto, 100% calc(100% - 1em);
    .fs-16 {
      font-size: 1.3em;
    }
  }
  @media (max-width: $bp-md) {
    padding: 4em 1.5em 1em;
    .fs-20 {
      font-size: 1.5em;
    }
  }
  @media (max-width: $bp-sm) {
    padding: 3em 1em 1em;
    font-size: 10px;
    .fs-16 {
      font-size: 1.2em;
    }
  }
  .phone {
    position: absolute;
    bottom: -7%;
    right: -10%;
    width: 50%;
    @media (max-width: $bp-xl) {
      bottom: -5%;
      width: 45%;
    }
    @media (max-width: $bp-lg) {
      width: 55%;
      right: -10%;
    }
    @media (max-width: $bp-md) {
      width: 60%;
      right: -20%;
      bottom: 5%;
    }
  }
}

.product-slider {
  overflow: visible !important;
  padding: 1em 0px;
  .swiper-slide {
    width: fit-content;
  }
}

.product-card-mini {
  max-width: 380px;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 1em;
  @include shadow();
  border-radius: $radius;
  @include flex(row, flex-start, stretch, nowrap);
  @media (max-width: $bp-lg) {
    flex-direction: column;
    max-width: calc(200px + 2em);
  }
  @media (max-width: $bp-sm) {
    max-width: calc(145px + 2em);
  }
  img {
    --card-mini-w: 130px;
    @include roundedImg(var(--card-mini-w), $radius, scale-down);
    margin-right: $radius;
    @media (max-width: $bp-lg) {
      --card-mini-w: 200px;
      margin-right: 0px;
    }
    @media (max-width: $bp-sm) {
      --card-mini-w: 145px;
      margin-bottom: 10px;
    }
  }
  figcaption {
    flex: 1;
    @include flex(column, space-between, stretch, nowrap);
    h6 {
      @media (max-width: $bp-sm) {
        font-weight: 400;
      }
    }
    a {
      transition: color $transition;
      &:hover {
        color: var(--main-color);
      }
    }
    @media (max-width: $bp-sm) {
      button {
        width: 100%;
      }
    }
  }
}
.sticky-box {
  position: sticky;
  z-index: 10;
  top: var(--h-height);
  left: 0px;
  width: 100%;
  padding: 0.5em 0.75em;
  @include shadow();
  background-color: var(--bg-catalog-menu);
  border-radius: $radius;
  @include flex(row, space-between, center, nowrap);
  @media (max-width: $bp-lg) {
    margin-top: 0 !important;
    top: var(--h-height) !important;
    padding: 0em;
    box-shadow: none;
  }
  &.show {
    max-width: 100% !important;
    background-color: var(--bg-catalog-menu);
    border-radius: 0px !important;
  }
}
.categories {
  user-select: none;
  @include flex(row, space-between, flex-start, nowrap);
  max-width: 100%;
  flex: 1;
  &-wrap {
    position: relative;
    max-width: 96%;
    padding: 0px 2.75em;
    @media (max-width: $bp-lg) {
      width: 100%;
      max-width: 100%;
      padding: 0px 0px;
    }
  }
  &-slider {
    margin: 0px !important;
    position: unset !important;
    padding: 0.75em !important;
    @media (max-width: $bp-lg) {
      width: 100%;
      padding: 0.75em 0.25em !important;
    }
    .swiper-slide {
      width: fit-content;
    }
    .swiper-button {
      color: var(--main-color-text);
      @media (max-width: $bp-lg) {
        display: none;
      }
      &-prev {
        left: 0px;
        box-shadow: 20px 1px 12px 0px var(--bg-header) !important;
        &::after {
          font-size: 20px;
        }
      }
      &-next {
        right: 0px;
        box-shadow: -20px 1px 12px 0px var(--bg-header) !important;
        &::after {
          font-size: 20px;
        }
      }
    }
    &-disabled {
      .swiper-wrapper {
        flex-wrap: wrap;
        transform: unset !important;
      }
      .swiper-slide {
        margin-bottom: 1em;
      }
    }
  }
  .btn-8 {
    width: 100%;
    font-size: 1em;
    svg {
      font-size: 1.5em;
      @media (max-width: $bp-sm) {
        font-size: 1.75em;
      }
    }
  }
  &-btn {
    color: var(--main-color);
    @include flex();
    padding: 1.3em 0px;
    @media (max-width: $bp-lg) {
      display: none;
    }
  }
}
.CategoryGroup {
  &:not(:last-child) {
    padding-bottom: 3em;
    margin-bottom: 4em;
    border-bottom: 1px solid var(--bg-second);
  }
}
.filter-line {
  @include flex(row, space-between, center, nowrap);
  margin-bottom: 3em;
  @media (max-width: $bp-lg) {
    align-items: stretch;
  }
  @media (max-width: $bp-sm) {
    align-items: center;
    margin-bottom: 1.5em;
  }
  .title {
    margin-bottom: 0px;
    margin-right: 1.5em;
    @media (max-width: $bp-xl) {
      display: none;
    }
  }
  .subcategories-list {
    @include list();
    @include flex(row, flex-start, center, wrap);
    @media (max-width: $bp-lg) {
      display: none;
    }
    li {
      margin-right: 1em;
    }
  }
  .btn-search {
    margin-right: auto;
    padding: 0.55em;
    color: $dark;
    &:hover,
    &:active {
      color: #fff;
    }
    @media (max-width: $bp-lg) {
      order: 2;
      margin-right: 1.5em;
      margin-left: auto;
    }
    @media (max-width: $bp-sm) {
      margin-right: 1em;
      padding: 0.35em;
    }
    svg {
      font-size: 1.5em;
      @media (max-width: $bp-lg) {
        font-size: 1.75em;
      }
    }
  }
  .btn-filter {
    margin-left: auto;
    margin-right: 1.5em;
    padding: 0.55em;
    @media (max-width: $bp-lg) {
      order: 3;
      margin-left: unset;
    }
    @media (max-width: $bp-sm) {
      margin-right: 1em;
      padding: 0.35em;
    }
    svg {
      font-size: 1.5em;
      @media (max-width: $bp-lg) {
        font-size: 1.75em;
      }
    }
  }
  .select {
    @media (max-width: $bp-lg) {
      order: 1;
    }
  }
  .btn-subcategories {
    padding: 0.55em;
    @media (min-width: 991.98px) {
      display: none;
    }
    @media (max-width: $bp-lg) {
      order: 4;
    }
    @media (max-width: $bp-sm) {
      padding: 0.35em;
    }
    svg {
      font-size: 1.5em;
      @media (max-width: $bp-lg) {
        font-size: 1.75em;
      }
    }
  }
}
.line-height-100 {
  height: 100% !important;
}
.product-gift {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0.8em;
  .title {
    @include clamp(2, 1.2em);
    min-height: auto !important;
    text-align: left !important;
  }
  @media (max-width: $bp-sm) {
    padding: 1em 0em;
  }
  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: $radius;
    margin-right: 1em;
    @media (max-width: $bp-sm) {
      width: 60px;
      height: 60px;
    }
  }
}
.product {
  user-select: none;
  &-img {
    position: relative;
    width: 100%;
    height: 280px;
    object-fit: cover;
    top: 0px;
    transition: all 150ms ease-out 0s;
    .lazy-load-image-loaded {
      display: flex !important;
    }
    &:hover {
      top: 3px;
    }
    &.no-hover {
      &:hover {
        top: 0px !important;
      }
    }
    margin-bottom: 1em;

    @media (max-width: $bp-xxl) {
      height: 240px;
    }
    @media (max-width: $bp-xl) {
      height: 210px;
    }
    @media (max-width: $bp-md) {
      height: 155px;
    }
    @media (max-width: $bp-sm) {
      height: 43vw;
    }
    img {
      width: 100%;
      height: 280px;
      object-fit: cover;
      object-position: center;
      border-radius: $radius;
      @media (max-width: $bp-xxl) {
        height: 240px;
      }
      @media (max-width: $bp-xl) {
        height: 210px;
      }
      @media (max-width: $bp-md) {
        height: 155px;
      }
      @media (max-width: $bp-sm) {
        height: 43vw;
      }
    }

    &.rectangle {
      height: 200px;
      @media (max-width: $bp-xxl) {
        height: 200px;
      }
      @media (max-width: $bp-xl) {
        height: 160px;
      }
      @media (max-width: $bp-md) {
        height: 120px;
      }
      @media (max-width: $bp-sm) {
        height: 32vw;
      }
      img {
        height: 200px;
        @media (max-width: $bp-xxl) {
          height: 200px;
        }
        @media (max-width: $bp-xl) {
          height: 160px;
        }
        @media (max-width: $bp-md) {
          height: 120px;
        }
        @media (max-width: $bp-sm) {
          height: 32vw;
        }
      }
    }
    &-contain {
      object-fit: contain !important;
      img {
        object-fit: contain !important;
      }
    }
  }

  .title {
    @include clamp(4, 1.2em);
    min-height: 2.4em;
    color: var(--main-color-text-title);
  }
  .subtitle {
    margin-top: 10px;
  }
  &-stickers {
    position: absolute;
    top: 1em;
    left: 1em;
    @include list();
    @include flex(row, flex-start, center, wrap);
    @media (max-width: $bp-xl) {
      left: 0px;
    }
    li {
      &:not(:first-child) {
        margin-left: 0.25em;
      }
      img {
        width: 2.5em;
        height: 2.5em;
        @media (max-width: $bp-md) {
          width: 2em;
          height: 2em;
        }
      }
    }
  }
  &-tags {
    position: absolute;
    top: 1em;
    right: 1em;
    @include list();
    @include flex(column, flex-start, flex-end, wrap);
    @media (max-width: $bp-xl) {
      right: 0px;
    }
    li {
      &:not(:first-child) {
        margin-top: 0.35em;
      }
      div {
        border: 1px solid currentColor;
        font-weight: 600;
        font-size: 0.9em;
        padding: 0.5em 1em;
        background-color: rgba($color: #fff, $alpha: 0.75);
        border-radius: $radius;
        transform: rotate(-3.5deg);
        @media (max-width: $bp-md) {
          font-size: 0.75em;
        }
        &.new {
          color: $red;
        }
        &.hit {
          color: $orange;
        }
      }
    }
  }
  .btn-fav {
    position: absolute;
    bottom: 0.8em;
    right: 0.8em;
    z-index: 1;
  }
  p {
    @include clamp(2, 1.2em, true);
  }
  p.desc-full {
    @include clamp(15, 1.2em, false);
    // white-space: break-spaces;
    height: auto;
  }
  hr {
    background-color: var(--bg-second);
    height: 1px;
    border: none;
    opacity: 1;
  }
}
html.theme-font-align-center .filterGrid h4 {
  text-align: center !important;
}
html.theme-font-align-center .product .title {
  text-align: center !important;
}
html.theme-font-align-center .product .subtitle {
  text-align: center !important;
}
html.theme-font-align-center .product .desc {
  text-align: center !important;
}
html.theme-font-title-amaticsc .product .title {
  font-family: "Amatic SC" !important;
  font-size: 32px !important;
  @media (max-width: $bp-sm) {
    font-size: 22px !important;
  }
}
html.theme-font-title-amaticsc .product .subtitle {
  font-family: "Open Sans", "Arial", "Tahoma", "Verdana" !important;
  font-size: 16px !important;
  color: var(--main-color-text-subtitle);
  @media (max-width: $bp-sm) {
    font-size: 14px !important;
  }
}
html.theme-font-robotocondensed .product .subtitle {
  font-family: "Roboto Condensed", "Arial", "Tahoma", "Verdana" !important;
}
html.theme-font-title-amaticsc .productPage h1 {
  font-family: "Amatic SC" !important;
  line-height: 1.1em;
  font-size: 50px !important;
}

.productPage {
  @media (max-width: $bp-md) {
    h1 {
      flex: 1;
      color: var(--main-color-text-title);
    }
  }
  .subtitle {
    margin-top: 10px;
  }
  min-height: 450px;
  &-content {
    position: sticky;
    top: 0px;
    min-height: 565px;
    @media (max-width: $bp-sm) {
      min-height: auto !important;
    }
  }
  &-img-modal {
    width: 100%;
    min-height: 100% !important;
    max-height: 570px;
    justify-content: center;
    object-fit: contain;
    border-radius: $radius;
    @media (max-width: $bp-lg) {
      height: auto;
      margin-bottom: 1em;
    }
    @media (max-width: $bp-sm) {
      height: 85vw;
    }
  }
  &-img {
    position: sticky;
    top: 50px;
    width: 100%;
    justify-content: center;
    max-height: 450px;
    object-fit: contain;
    border-radius: $radius;
    @media (max-width: $bp-xxl) {
      height: 450px;
    }
    @media (max-width: $bp-xl) {
      height: 215px;
    }
    @media (max-width: $bp-lg) {
      height: auto;
      margin-bottom: 1em;
    }
    @media (max-width: $bp-sm) {
      height: 85vw;
    }
  }
  &-photo {
    position: sticky;
    top: 80px;
    &-modal {
      top: 0px !important;
    }
    justify-content: center;
    .lazy-load-image-loaded {
      align-self: center;
      display: block !important;
      width: 100%;
    }
    @include flex(row, flex-start, flex-start, nowrap);
    .thumbSlider {
      cursor: pointer;
      width: 70px;
      margin-right: 1em;
      height: var(--img);
      .swiper-slide {
        height: 70px;
        img {
          width: 100%;
          align-self: center;
          @include roundedImg(70px, $radius * 0.5, contain);
          background-color: var(--bg-second);
          border: 1px solid var(--bg-btn);
        }
      }
    }
    .mainSlider {
      flex: 1;
      cursor: grab;
    }
  }
  &-edit {
    .top {
      @include flex(row, flex-start, flex-end, nowrap);
      button {
        position: relative;
        z-index: 1;
        background-color: $dark;
        color: var(--main-color-text);
        border-radius: $radius $radius 0px 0px;
        height: calc($radius * 2);
        @include flex();
        transition: $transition;
        transition-property: background-color, color;
        &:first-child {
          padding: 0em 2.5em 0em 1.5em;
        }
        &:nth-child(2) {
          padding: 0em 2.5em;
          margin-left: -2em;
        }
        span {
          margin-left: 0.25em;
        }
        .corner {
          position: absolute;
          z-index: 1;
          bottom: 0px;
          font-size: $radius;
          color: $dark;
          transition: color $transition;
          &-right {
            @extend .corner;
            left: 100%;
          }
          &-left {
            @extend .corner;
            right: 100%;
            transform: rotateY(180deg);
          }
        }
        &.active {
          background-color: var(--bg-second);
          // color: $black;
          z-index: 2;
          .corner {
            color: var(--bg-second);
          }
        }
      }
    }
    .box {
      background-color: var(--bg-second);
      padding: 0.75em;
      border-radius: 0px $radius $radius $radius;
      ul {
        --mt: 5px;
        --h: 60px;
        @include list();
        max-height: calc(var(--h) * 5 + var(--mt) * 4);
        overflow: auto;
        @include scrollCustomized();
        padding-right: var(--mt);
        @media (max-width: $bp-xxl) {
          --h: 54px;
        }
        @media (max-width: $bp-xl) {
          --h: 47.25px;
        }
        li {
          &:not(:first-child) {
            margin-top: var(--mt);
          }
        }
      }
    }
  }
  &-price {
    @include flex(row, flex-start, center, nowrap);
    font-size: 1.2em;
    @media (max-width: $bp-md) {
      font-size: 1em;
      position: fixed;
      bottom: var(--f-height);
      left: 0px;
      width: 100%;
      height: 60px;
      z-index: 90;
      background-color: var(--bg-main);
      justify-content: space-between;
      button {
        width: 270px;
      }
    }
    @media (max-width: $bp-sm) {
      padding: 0px 1.5em;
      button {
        width: 100%;
        flex: 1;
      }
    }
  }
  .select-button {
    padding: 0.6em 1em;
  }
  .notice {
    padding: 0.75em 6em 0.75em 1em;
  }
}
.ingredient {
  @include flex(column, center, center, nowrap);
  @include shadow();
  text-align: center;
  align-self: center;
  background-color: var(--bg-main);
  border-radius: $radius * 0.5;
  padding: 7px;
  margin-bottom: 20px;
  min-height: 90%;
  border: 2px solid transparent;
  font-size: 0.8em;
  &.active {
    background-color: var(--main-color-outline);
    border-color: var(--main-color);
  }
  &-img {
    width: 5em;
    height: 5em;
    object-fit: contain;
    align-self: center;
    border-radius: $radius * 0.25;
    margin-bottom: 5px;
  }
  &-title {
    flex: 1;
    line-height: 100%;
    margin-bottom: 7px;
    font-size: 12px;
    white-space: break-spaces;
    word-break: break-word;
  }
  &-price {
    font-weight: 700;
    font-size: 0.9em;
  }
  button {
    padding: 0.33em 0.6em;
    margin-left: $radius * 0.5;
  }
}

.wish {
  @include flex(row, flex-start, center, nowrap);
  @include shadow();
  background-color: var(--bg-main);
  border-radius: $radius * 0.5;
  padding: 12px;
  border: 2px solid transparent;
  font-size: 0.8em;
  &.active {
    background-color: var(--main-color-outline);
    border-color: var(--main-color);
  }
  &-title {
    flex: 1;
    line-height: 100%;
    font-size: 13px;
  }
  &-price {
    font-weight: 700;
    font-size: 0.9em;
  }
  button {
    padding: 0.33em 0.6em;
    margin-left: $radius * 0.5;
  }
}

html.theme-font-title-amaticsc .filterGrid h4 {
  font-family: "Amatic SC" !important;
  font-weight: bold;
  font-size: 45px;
}

.searchForm {
  --size: 2.75em;
  position: relative;
  @media (max-width: $bp-md) {
    position: static;
  }
  input {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 10;
    max-width: var(--size);
    width: 800px;
    height: var(--size);
    border-radius: 1.5em;
    border: 1px solid var(--light-gray);
    box-shadow: none !important;
    background-color: var(--bg-second);
    transition: max-width $transition, border $transition;
    @media (max-width: $bp-md) {
      opacity: 0;
      z-index: -1;
    }
  }
  button {
    position: relative;
    z-index: 10;
    width: var(--size);
    height: var(--size);
    border: none;
    border-radius: 1.5em;
    padding: 0px;
    background-color: transparent;
    color: var(--light-gray);
    transition: color $transition;
    @media (max-width: $bp-md) {
      border: 1px solid var(--light-gray);
    }
    svg {
      font-size: 1.5em;
      color: inherit;
    }
    &:hover,
    &:focus,
    &:active {
      color: var(--main-color);
    }
  }
  &.opened {
    input {
      max-width: 800px;
      border: 1px solid var(--main-color);
      @media (max-width: $bp-xl) {
        max-width: 600px;
      }
      @media (max-width: $bp-lg) {
        max-width: 370px;
      }
      @media (max-width: $bp-md) {
        max-width: 100%;
        opacity: 1;
        z-index: 100;
      }
    }
  }
}

.offer {
  width: 100%;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.02);
  background-color: rgba(255, 255, 255, 0.05);
  @include shadow();
  // @include shadowF();
  // transition: transform 200ms linear;
  @media (max-width: $bp-sm) {
    font-size: 11px;
  }
  img {
    position: relative;
    z-index: 1;
    width: 100%;
    // height: 180px;
    object-fit: cover;
    // @media (max-width: $bp-xxl) {
    //   height: 155px !important;
    // }
    // @media (max-width: $bp-xl) {
    //   height: 130px !important;
    // }
    // @media (max-width: $bp-lg) {
    //   height: 145px !important;
    // }
    // @media (max-width: $bp-md) {
    //   height: 220px !important;
    // }
    // @media (max-width: $bp-sm) {
    //   height: 210px !important;
    // }
    // @media (max-width: $bp-xs) {
    //   height: 175px !important;
    // }
    // @media (max-width: $bp-xxs) {
    //   height: 145px !important;
    // }
  }
  // &.full {
  //   img {
  //     height: 370px;
  //     @media (max-width: $bp-xxl) {
  //       height: 335px !important;
  //     }
  //     @media (max-width: $bp-lg) {
  //       height: 300px !important;
  //     }
  //     @media (max-width: $bp-md) {
  //       height: 230px !important;
  //     }
  //     @media (max-width: $bp-sm) {
  //       height: 220px !important;
  //     }
  //     @media (max-width: $bp-xs) {
  //       height: 180px !important;
  //     }
  //     @media (max-width: $bp-xxs) {
  //       height: 145px !important;
  //     }
  //   }
  // }
  &-body {
    padding: 1em;
    &-title {
      font-size: 22px;
      color: var(--main-color-text-title);
      @include clamp(2, 1.3em, true);
    }
    &-desc {
      @include clamp(1, 1.3em, true);
    }

    @include flex(column, flex-end, stretch, nowrap);
  }
}
.sw-offers {
  overflow: hidden;
  padding: 1em 0.5em;
  &:hover {
    .offer {
      transform: rotate(0deg) !important;
    }
  }
  .swiper-slide {
    width: var(--offer-w);
    @media (min-width: 991.98px) {
      &:nth-child(odd) {
        .offer {
          transform: rotate(3deg);
        }
      }
      &:nth-child(even) {
        .offer {
          transform: rotate(-3deg);
        }
      }
    }
  }
}
.offer-page-img {
  width: 100%;
  height: var(--offer-w);
  border-radius: $radius;
}

.box {
  background-color: var(--bg-second);
  @include scrollCustomized();
  padding: 1em;
  border-radius: $radius;
  font-size: 1.2em;
  @media (max-width: $bp-xl) {
    font-size: 1.1em;
  }
  @media (max-width: $bp-md) {
    padding: 1em;
  }
}
.offer-product {
  padding: 1.25em 0px;
  width: 100%;
  display: grid;
  grid-template-columns: 100px auto 280px;
  grid-template-rows: auto;
  gap: 1em;
  grid-template-areas: "img about price";
  @media (max-width: $bp-xxl) {
    grid-template-columns: 100px auto 250px;
  }
  @media (max-width: $bp-xl) {
    grid-template-columns: 100px auto 210px;
  }
  @media (max-width: $bp-md) {
    grid-template-columns: 120px auto;
    grid-template-rows: auto;
    grid-template-areas: "img about" "img price";
  }
  &-img {
    grid-area: img;
    img {
      width: 100%;
    }
  }
  &-about {
    grid-area: about;
    p {
      color: $dark;
      margin-bottom: 0.5em;
      @media (max-width: $bp-sm) {
        font-size: 0.9em;
      }
    }
  }
  &-price {
    grid-area: price;
    @include flex(row, flex-end, flex-start, nowrap);
    font-size: 1.2em;
    @media (max-width: $bp-xxl) {
      font-size: 1.1em;
    }
    @media (max-width: $bp-md) {
      justify-content: space-between;
    }
    @media (max-width: $bp-sm) {
      font-size: 1em;
    }
  }
  &s-list {
    li:not(:first-child) {
      border-top: 1px solid var(--bg-btn);
    }
  }
}

.sec-7 {
  .box {
    box-shadow: none;
    height: 750px;
    @include flex(column, flex-start, stretch, nowrap);
    @media (max-width: $bp-sm) {
      height: 350px;
      margin-top: 15px;
    }
  }
  ul {
    flex: 1;
    overflow: auto;
    @include scrollCustomized();
    a {
      border-radius: $radius;
      display: block;
      background-color: var(--bg-second-hover);
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 15px;
      opacity: 0.6;
      &.active {
        opacity: 1;
      }
    }
    li {
      padding: 1em 0em;
      border-bottom: none !important;
      h6 {
        font-size: 18px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .map {
    border-radius: $radius;
    background-color: var(--bg-second);
    overflow: hidden;
    @include safariFix();
    @include flex();
    height: 750px;

    @media (max-width: $bp-sm) {
      height: 350px;
      margin-top: 15px;
    }
  }
}
.map-header {
  background-color: var(--bg-second);
  overflow: hidden;
  @include safariFix();
  @include flex();
  height: 585px;

  @media (max-width: $bp-sm) {
    height: 350px;
  }
}
.ymaps-2-1-79-map-copyrights-promo,
.ymaps-2-1-79-copyright {
  display: none !important;
}
.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  svg {
    stroke: var(--main-color);
  }
}

.cart {
  margin-bottom: 3em;
  &-filter {
    @include flex();
    background-color: var(--bg-second);
    padding: 0.75em 1em;
    border-radius: $radius;
    margin-bottom: 0.5em;
  }
  &-item {
    user-select: none;
    position: relative;
    @include flex(row, space-between, flex-start, nowrap);
    padding: 0.8em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    @media (max-width: $bp-md) {
      flex-wrap: wrap;
    }
    @media (max-width: $bp-sm) {
      padding: 1em 0em;
    }
    .order-1 {
      padding: 0.9em 0;
    }
    .left {
      @include flex(row, flex-start, flex-start, nowrap);
      width: 100%;
      @media (max-width: $bp-xxl) {
        width: 100%;
      }
      @media (max-width: $bp-md) {
        width: 100%;
      }
      .text {
        max-width: 80%;
        flex: 1;
        h6 {
          font-size: 16px;
          width: fit-content;
          .tag {
            float: right;
            color: var(--main-color);
            font-size: 0.75em;
            border: 1px solid var(--main-color);
            border-radius: $radius;
            padding: 0.3em 0.6em;
            transform: rotate(-3.5deg);
            margin-left: 1em;
            @media (max-width: $bp-sm) {
              position: absolute;
              top: 1em;
              left: $radius * 1.5;
            }
          }
        }
        p {
          margin-bottom: 0.5em;
          &.consist {
            @include clamp(2, 1.2em, true);
            // @media (max-width: $bp-sm) {
            //   display: none;
            // }
          }
        }
      }
    }
    .right {
      max-width: 240px;
      @include flex(row, space-between, flex-start, nowrap);
      @media (max-width: $bp-md) {
        margin-top: 1em;
        align-items: center;
      }
      @media (max-width: $bp-sm) {
        margin-top: 0.5em;
        min-width: 100% !important;
        flex: 1;
      }
      .btn-fav {
        order: 3;
      }
    }
    img {
      width: 90px;
      height: 90px;
      object-fit: cover;
      border-radius: $radius;
      margin-right: 1em;
    }
    &-ingredients {
      padding-top: 0.25em;
      @include list();
      font-size: 0.9em;
      li {
        &:not(:last-child) {
          margin-bottom: 0.25em;
        }
        &::before {
          content: "+";
          font-weight: 600;
          color: $green;
          margin-right: 0.5em;
        }
      }
    }
    &-ingredients-minus {
      padding-top: 0.25em;
      @include list();
      font-size: 0.9em;
      li {
        &:not(:last-child) {
          margin-bottom: 0.25em;
        }
        &::before {
          content: "-";
          font-weight: 600;
          color: $red;
          margin-right: 0.5em;
        }
      }
    }

    &.mini-cart-item .left,
    &.mini-cart-item {
      img {
        border-radius: 0.6em;
        width: 45px !important;
        height: 45px !important;
      }
      .text p.consist {
        -webkit-line-clamp: 1 !important;
        height: 1.2em !important;
      }
    }
  }
  &-box {
    background-color: var(--bg-second);
    padding: 0.8em;
    border-radius: $radius;
  }
}
.checkoutProduct {
  user-select: none;
  font-size: 0.9em;
  background-color: var(--bg-main);
  padding: $radius * 0.5;
  border-radius: 1.2em;
  @include shadow();
  img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 0.8em;
    margin-right: 0.6em;
  }
  &-count {
    display: inline-block;
    border: 1px solid var(--bg-btn);
    padding: 0.2em 0.6em;
    background-color: var(--bg-second);
    border-radius: $radius;
  }
}

footer {
  padding: 20px 0px;
  background-color: var(--bg-footer);
  min-height: 61px;
  @media (max-width: $bp-lg) {
    background-color: var(--bg-footer);
    position: fixed;
    bottom: -1px;
    left: 0px;
    width: 100%;
    z-index: 900;
  }
  @media (max-width: $bp-sm) {
    padding: 10px 0px;
  }
  a {
    transition: color 0.2s;
    color: #fff;
    &:hover,
    &:focus,
    &:active {
      color: var(--main-color);
    }
  }
  .desktop {
    height: 100%;
    @include flex(row, space-between, center, nowrap);
    .logo {
      max-width: 160px;
      max-height: 100px;
    }
  }
  .mobile {
    ul {
      height: 100%;
      @include list();
      @include flex(row, space-between, center, nowrap);
      li {
        a {
          @include flex(row, flex-start, center, nowrap);
          padding: 0.5em;
          border-radius: $radius * 0.5;
          background-color: transparent;
          color: #fff;
          overflow: hidden;
          // transition: $transition;
          // transition-property: background-color, max-width;
          &.active .text {
            color: #fff !important;
          }
          svg {
            width: 1.6em !important;
            height: 1.6em !important;
            color: #fff;
            // transition: color $transition;
          }
          .text {
            color: #fff;
            margin-left: 3px;
            overflow: hidden;
            max-width: 0px;
            // display: none;
            // transition: max-width $transition;
          }
          .badge {
            bottom: -4px;
            right: -4px;
            font-size: 9px;
            background-color: var(--main-color);
            padding: 0px;
            color: #fff;
            min-width: 1.5em;
            height: 1.5em;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
          }
          &.active {
            background-color: var(--main-color);
            max-width: 130px;
            .badge {
              background-color: var(--bg-second);
              color: var(--main-color);
            }
            svg {
              // font-size: 1.2em;
              color: #fff;
            }
            .text {
              display: block;
              max-width: fit-content;
            }
          }
        }
      }
    }
  }
}

/***********
ACCOUNT
***********/
.account {
  position: relative;
  .icon {
    --w: 95px;
    background-color: var(--main-color-outline);
    border-radius: 50%;
    @include roundedImg(var(--w));
    margin-right: 1em;
    @include flex();
    @media (max-width: $bp-xxl) {
      --w: 80px;
      margin-right: 0.75em;
    }
    @media (max-width: $bp-xl) {
      --w: 70px;
    }
    @media (max-width: $bp-lg) {
      --w: 60px;
    }
    span {
      color: #fff;
      font-size: 3.125em;
      font-weight: 600;
    }
  }
  .gradient-block {
    display: block;
    background: url(imgs/bg/gradient.jpg) no-repeat center;
    background-size: cover;
    border-radius: $radius * 0.5;
    width: 100%;
    height: 100%;
    min-height: 125px;
  }
  .box {
    background-color: var(--bg-second);
    border-radius: $radius * 0.5;
    &-blue {
      background-color: var(--main-color-outline);
      border-radius: $radius * 0.5;
    }
  }
  .link-return {
    @include flex(row, flex-start, center, nowrap);
    color: var(--main-color);
    margin-right: 2em;
    font-size: 1.1em;
    svg {
      font-size: 1.7em;
      margin-right: 0.25em;
      stroke-width: 1px;
    }
  }
  &-top {
    margin-bottom: 2em;
    .box {
      border-radius: $radius;
      padding: 1em;
      @media (max-width: $bp-xxl) {
        padding: 0.75em;
      }
    }
  }
  &-nav {
    ul {
      @include list();
      li {
        &:not(:first-child) {
          margin-top: 0.3em;
        }
        a {
          color: var(--main-color-text);
          @include flex(row, flex-start, center, nowrap);
          padding: 0.75em 1em;
          border-radius: $radius;
          transition: $transition;
          transition-property: color, background-color;
          svg {
            font-size: 1.5em;
          }
          div {
            font-size: 1.1em;
            font-weight: 600;
            margin-left: 0.5em;
            flex: 1;
          }
          .badge {
            background-color: var(--main-color);
            padding: 0.34em;
            border-radius: $radius;
            color: #fff !important;
          }
          &:hover {
            color: var(--main-color-text);
            background-color: var(--bg-second);
          }
          &.active {
            background-color: var(--main-color);
            color: var(--main-color-text-revers);
            .badge {
              background-color: #fff !important;
              color: var(--main-color) !important;
            }
          }
        }
      }
    }
  }
  &-menu {
    .btn-settings {
      background-color: var(--bg-btn);
      color: var(--main-color-text);
      font-size: 1.8em;
      @include flex();
      padding: 0.3em;
      border-radius: $radius;
      svg {
        stroke-width: 1px;
      }
    }
    nav {
      background-color: var(--bg-second);
      @include lightShadow();
      @include safariFix();
      overflow: hidden;
      border-radius: $radius * 0.5;
      ul {
        @include list();
        li {
          border-bottom: 1px solid var(--bg-btn);
          a {
            @include flex();
            font-weight: 400;
            font-size: 1.2em;
            padding: 0.75em 1em;
            &:active,
            &.active {
              background-color: var(--main-color-outline);
            }
            div {
              flex: 1;
            }
            svg {
              stroke-width: 1px;
              font-size: 1.5em;
              margin-right: 0.5em;
            }
          }
        }
      }
    }
  }

  .order-card {
    display: grid;
    grid-template-columns: 70px 150px 120px auto 100px 150px;
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      "num time status delivery price btn"
      "num time status address price btn";
    gap: 0em 1em;
    font-size: 0.9em;
    @media (max-width: $bp-xxl) {
      grid-template-columns: 50px 140px 110px auto 70px 140px;
    }
    @media (max-width: $bp-xl) {
      grid-template-columns: 40px 115px 100px auto 60px 120px;
      gap: 0em 0.5em;
    }
    @media (max-width: $bp-lg) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      gap: 0em;
      grid-template-areas:
        "num time"
        "delivery status"
        "address address"
        "price price"
        "btn btn";
      @include lightShadow();
      border-radius: $radius;
      font-size: 1.1em;
      padding: 0.5em 0;
    }
    @media (max-width: $bp-md) {
      font-size: 0.9em;
    }
    @media (max-width: $bp-sm) {
      font-size: 1em;
    }
    &.top {
      padding: 1em;
      background-color: var(--bg-btn);
      grid-template-rows: 1fr;
      grid-template-areas: "num time status delivery price btn";
      color: $dark;
      font-weight: 600;
      border-radius: $radius $radius 0px 0px;
    }
    &-num {
      grid-area: num;
      @include flex(row, flex-start, center, nowrap);
      @media (max-width: $bp-lg) {
        padding: 0.5em 0em 0.5em 1em;
      }
    }
    &-time {
      grid-area: time;
      @include flex(row, flex-start, center, nowrap);
      @media (max-width: $bp-lg) {
        color: $dark;
        justify-content: flex-end;
        padding: 0.5em 1em 0.5em 0;
      }
    }
    &-status {
      grid-area: status;
      @include flex(row, flex-start, center, nowrap);
      @media (max-width: $bp-lg) {
        padding: 0.25em 1em;
        justify-content: flex-end;
      }
    }
    &-delivery {
      grid-area: delivery;
      @include flex(row, flex-start, center, nowrap);
      @media (max-width: $bp-lg) {
        color: var(--main-color-text);
        padding: 0.25em 1em;
      }
    }
    address {
      grid-area: address;
      @include flex(row, flex-start, center, nowrap);
      @media (max-width: $bp-lg) {
        padding: 0.5em 1em 0.75em;
        border-bottom: 1px solid var(--bg-btn);
      }
    }
    &-price {
      grid-area: price;
      @include flex(row, flex-start, center, nowrap);
      @media (max-width: $bp-lg) {
        padding: 0.5em 1em;
        font-weight: 600;
        @include flex(column, flex-start, stretch, nowrap);
      }
    }
    &-btn {
      grid-area: btn;
      @include flex(row, flex-end, center, nowrap);
      @media (max-width: $bp-lg) {
        padding: 0.5em 1em;
        border-top: 1px solid var(--bg-btn);
      }
      a {
        color: var(--main-color);
        @include flex();
        svg {
          font-size: 1.5em;
          background-color: var(--main-color-outline);
          border-radius: 50%;
          margin-left: 0.25em;
          padding: 0.15em;
        }
      }
    }
    &-slider {
      width: 100%;
      .swiper-slide {
        width: fit-content;
      }
      img {
        --w: 50px;
        @include roundedImg($w: var(--w), $rad: $radius, $fit: contain);
      }
    }
  }
  .order-list {
    @include list();
    & > li {
      border-bottom: 1px solid var(--bg-btn);
      padding: 1.25em 0.5em;
      margin-bottom: 0.5em;
    }
  }
  .order-item {
    display: grid;
    // grid-template-columns: var(--w) auto 110px 50px;
    grid-template-rows: auto;
    grid-template-areas:
      "img data quantity price"
      "img info quantity price"
      "img show . ."
      ". extra . .";
    // gap: 0.5em 1em;
    @media (max-width: $bp-xxl) {
      // grid-template-columns: var(--w) auto 50px 50px;
      // gap: 0.5em 0.75em;
    }
    @media (max-width: $bp-sm) {
      // gap: 0.25em 0.5em;
      grid-template-areas:
        "img data data data"
        "img info price quantity"
        "img show show show"
        "extra extra extra extra";
    }
    img {
      @include roundedImg($w: var(--w), $rad: $radius * 0.5, $fit: contain);
      grid-area: img;
    }
    .text {
      .consist {
        color: $dark;
        @include clamp(2, 1.2em, true);
        @media (max-width: $bp-md) {
          display: none;
        }
      }
    }
    .info {
      grid-area: info;
      color: $dark;
      @include flex(row, flex-start, center, nowrap);
      @include list();
      li {
        &:not(:first-child) {
          margin-left: 1em;
        }
      }
    }
    .show {
      grid-area: show;
      @media (max-width: $bp-md) {
        color: var(--main-color);
        font-weight: 300;
      }
    }
    .extra {
      grid-area: extra;
      color: $dark;
    }
    .price {
      grid-area: price;
      font-size: 1.2em;
      font-weight: 600;
      @media (max-width: $bp-sm) {
        @include flex();
        font-size: 1em;
        font-weight: 400;
      }
    }
  }
  .addresses {
    &-list {
      border-radius: $radius;
      background-color: var(--bg-second);
      @include list();
      li {
        padding: 1em 1.25em;
        @include flex(row, space-between, center, nowrap);
        @media (max-width: $bp-sm) {
          flex-direction: column;
          align-items: stretch;
          padding: 0.5em 1em;
        }
        &:not(:first-child) {
          border-top: 1px solid var(--bg-btn);
        }
      }
    }
  }
  .bonus-program {
    @media (min-width: 991.98px) {
      .link-return {
        display: none;
      }
    }
    ul {
      @include list();
      padding: 0.75em 0em 0.5em;
      li {
        border-bottom: 1px solid var(--bg-btn);
        padding: 0.75em 1.25em;
        display: grid;
        grid-template-columns: 30fr 20fr 50fr;
        grid-template-rows: auto;
        grid-template-areas: "title date bonuses";
        gap: 1em;
        @media (max-width: $bp-xxl) {
          grid-template-columns: 40fr 20fr 40fr;
        }
        @media (max-width: $bp-md) {
          grid-template-columns: 1fr 2fr;
          grid-template-areas:
            "bonuses title"
            "bonuses date";
          gap: 0.5em;
        }
        @media (max-width: $bp-sm) {
          grid-template-columns: 1fr 1fr;
        }
        .title {
          grid-area: title;
          @include flex(row, flex-start, center, nowrap);
          @media (max-width: $bp-sm) {
            text-align: right;
            justify-content: flex-end;
          }
        }
        .date {
          grid-area: date;
          color: $dark;
          @include flex(row, flex-start, center, nowrap);
          @media (max-width: $bp-sm) {
            text-align: right;
            justify-content: flex-end;
          }
        }
        .bonuses {
          grid-area: bonuses;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto;
          gap: 1em;
          grid-template-areas: "income spending";
          @media (max-width: $bp-md) {
            display: flex;
            flex-direction: row;
            gap: 0.5em;
          }
        }
        .income {
          grid-area: income;
          width: 100%;
          text-align: center;
          @include flex();
          background-color: rgba($color: $green, $alpha: 0.1);
          color: $green;
          font-weight: 600;
          padding: 0.7em;
          border-radius: $radius * 0.5;
          @media (max-width: $bp-md) {
            width: 3.6em;
            height: 3.6em;
            border-radius: 50%;
          }
        }
        .spending {
          grid-area: spending;
          width: 100%;
          text-align: center;
          @include flex();
          background-color: var(--main-color);
          color: var(--main-color);
          font-weight: 600;
          padding: 0.7em;
          border-radius: $radius * 0.5;
          @media (max-width: $bp-md) {
            width: 3.6em;
            height: 3.6em;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .notifications {
    .link-return {
      @media (min-width: 991.98px) {
        display: none;
      }
    }
    &-list {
      border-radius: $radius;
      background-color: var(--bg-second);
      @include list();
      li {
        padding: 1em 1.25em;
        @include flex(row, space-between, center, nowrap);
        @media (max-width: $bp-sm) {
          flex-direction: column;
          align-items: stretch;
          padding: 0.5em 1em;
        }
        &:not(:first-child) {
          border-top: 1px solid var(--bg-btn);
        }
        .links {
          grid-area: links;
          @include flex();
          @media (max-width: $bp-md) {
            justify-content: flex-start;
          }
        }
        .date {
          grid-area: date;
          color: $dark;
          text-align: right;
          @include flex(row, flex-end, center, nowrap);
          @media (max-width: $bp-sm) {
            font-size: 0.9em;
          }
        }
      }
    }
  }
}
.support {
  background-color: var(--bg-second);
  border-radius: $radius;

  &-top {
    padding: 1em 1.5em;
    border-bottom: 1px solid var(--bg-btn);
  }
  &-form {
    border-top: 1px solid var(--bg-btn);
    padding: 0.5em 1em;
    @include flex(row, space-between, center, nowrap);
    @media (max-width: $bp-lg) {
      position: fixed;
      bottom: var(--f-height);
      left: 0px;
      width: 100%;
      z-index: 100;
    }
    input {
      border-color: transparent;
      box-shadow: none;
    }
  }
}
.product-modal {
  .modal-dialog {
    @media (max-width: $bp-sm) {
      height: 100% !important;
      max-height: 100% !important;
    }
  }
  .modal-content {
    min-height: 600px !important;
    max-height: 600px !important;
    border-radius: 20px !important;
    overflow: visible !important;
    @media (max-width: $bp-sm) {
      height: 100% !important;
      max-height: 100% !important;
      border-radius: 0px !important;
    }
  }
}
.fixed-price-product {
  padding: 15px 0px !important;
  bottom: -20px !important;
  background-color: var(--bg-main) !important;
}
.scroll-custom {
  @include scrollCustomized();
}
.scroll-hide {
  @include scrollCustomizedHide();
}
.chat {
  @include scrollCustomized();
  min-height: 550px;
  height: 100%;
  max-height: 65vh;
  overflow: auto;
  padding: 1.5em;
  display: flex;
  flex-direction: column-reverse;
  .message {
    margin-bottom: 2em;
    time {
      display: block;
      color: var(--light-gray);
      text-align: left;
      font-size: 0.7em;
    }
    .text {
      background-color: var(--bg-second-hover);
      border-radius: 12px;
      padding: 0.6em 0.8em;
      max-width: 400px;
      margin-right: 56px;
      margin-left: 0px;
      word-break: break-word;
    }
    .text.my {
      background-color: var(--main-color-outline);
      margin-left: 56px;
      margin-right: 0;
      time {
        text-align: right !important;
      }
    }
  }
}
.sidebar-chat {
  background-color: var(--bg-card) !important;
  .support-top {
    padding: 10px 15px !important;
  }
  .support-form {
    padding-left: 0px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    input {
      background-color: transparent !important;
    }
  }
  .chat {
    padding: 0.6em !important;
  }
}
.chat-bar {
  position: absolute;
  z-index: 9999;
  top: 0em;
  border-radius: 18px;
  overflow: hidden;
  right: 3.5em;
  width: 320px;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.2);
  background-color: var(--bg-card);
  .chat {
    height: 350px !important;
    min-height: 350px !important;
  }
  .text {
    max-width: 280px !important;
  }
}
.login {
  position: relative;
  min-height: calc(100vh - var(--h-height) - var(--f-height));
  height: 700px;
  &-forms {
    position: relative;
    z-index: 1;
    width: 62%;
    padding: 4em;
    @media (max-width: $bp-xl) {
      padding: 2em;
    }
    @media (max-width: $bp-md) {
      width: 100%;
    }
    @media (max-width: $bp-sm) {
      padding: 1em;
    }
    form {
      background-color: var(--bg-second);
      border-radius: $radius;
      padding: 2em;
      &.login-form {
        width: 80%;
        margin: 0 auto;
        @media (max-width: $bp-lg) {
          width: 100%;
          padding: 1.3em;
        }
      }
    }
  }
  &-toggler {
    position: absolute;
    top: 60px;
    right: 0%;
    bottom: 0px;
    z-index: 10;
    width: 38%;
    border-radius: 30px;
    background-color: var(--main-color);
    background-image: url(imgs/bg/entrance.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 50%;
    color: #fff;
    text-align: center;
    padding-top: 4em;
    .btn-40 {
      width: 40%;
    }
    .text {
      position: relative;
      overflow: hidden;
      color: #fff;
      &-1 {
        position: relative;
        z-index: 1;
        width: 100%;
        margin-left: 0%;
      }
      &-2 {
        @include absBG(10, 0px, 100%);
        left: -100%;
      }
    }
  }
  &-mobile {
    display: block;
    padding: 2em 0px;
  }
}

.city {
  a.active {
    color: var(--main-color);
    font-weight: 600;
  }
  .logo {
    max-height: 50px;
    max-width: 200px;
  }
}
.no-city {
  @include shadow();
  padding: 1.5em;
  background-color: var(--bg-main);
  border-radius: $radius;
  font-size: 1.2em;
  z-index: 100;
  position: absolute;
  top: var(--h-height);
}
/***********
ACCOUNT END
***********/

.modal {
  user-select: none;
  &-content {
    border: none;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
  }
  &-body {
    position: relative;
    overflow: hidden;
    border-radius: 20px !important;
  }
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  top: 65px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
}

.notification {
  box-sizing: border-box;
  padding: 13px 20px;
  box-shadow: 0px 0px 2px #919eab1f, 0px 8px 24px -4px #919eab33 !important;
  border-radius: 4px;
  color: var(--main-color-text);
  background-color: var(--bg-second) !important;
  border-left: 4px solid transparent;
  cursor: pointer;
  font-weight: 500;
  font-size: 1em;
  position: relative;
  margin-bottom: 15px;
}

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-exit {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-exit.notification-exit-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

.notification-info {
  border-color: #2f96b4;
}

.notification-success {
  border-color: #36b37e;
}

.notification-warning {
  border-color: #f89406;
}

.notification-error {
  border-color: #bd362f;
}
.delivery {
  user-select: none;
  min-height: 55px;
  padding: 5px 15px;
  display: flex;
  font-size: 14px;
  margin-right: 10px;
  justify-content: center;
  text-align: left;
  flex-direction: column;
  border-radius: 12px;
  border: 2px solid transparent;
  background-color: var(--bg-second);
  &.active {
    border-color: var(--main-color);
    background-color: var(--main-color-outline);
  }
  p {
    font-size: 13px;
    color: var(--main-color-text);
  }
}
.sidebar {
  position: fixed;
  z-index: 100;
  top: 40%;
  right: 0px;
  transform: translateY(-40%);
  background-color: var(--bg-second);
  border-radius: 0.75em 0px 0px 0.75em;
  padding: 0.35em;
  opacity: 1;
  transition: opacity $transition;
  @media (max-width: $bp-lg) {
    border-radius: 50%;
    top: unset;
    right: 0.5em;
    bottom: calc(var(--f-height) * 2 + 5px);
    transform: translateY(0%);
  }
  &.hide {
    opacity: 0;
  }
  ul {
    @include list();
    @include flex(column, center, stretch, nowrap);
    li {
      padding: 0.2em;
      @media (min-width: $bp-lg) {
        &:not(:first-child) {
          border-top: 1px solid var(--bg-btn);
        }
      }
      a,
      button {
        position: relative;
        @include flex();
        .badge {
          position: absolute;
          z-index: 2;
          top: -0.2em;
          right: -0.2em;
          background-color: var(--main-color);
          font-size: 0.6em;
          font-weight: 600;
          border-radius: 50%;
          width: 1.65em;
          height: 1.65em;
          @include flex();
        }
        svg {
          position: relative;
          z-index: 1;
          stroke-width: 0px;
          font-size: 2em;
          path {
            stroke-width: 1.25px;
          }
        }
      }
    }
  }
}
.scroll-top {
  position: fixed;
  z-index: 100;
  bottom: 2em;
  right: 2em;
  background-color: var(--bg-second);
  color: var(--main-color);
  border: 1px solid currentColor;
  border-radius: 50%;
  width: 3em;
  height: 3em;
  @include flex(column, center, center, nowrap);
  transition: $transition;
  opacity: 1;
  transform: scale(1);
  svg {
    font-size: 1.25em;
  }
  span {
    font-size: 0.7em;
  }
  &.hide {
    opacity: 0;
    transform: scale(0.5);
  }
  @media (max-width: 991px) {
    bottom: 5em;
  }
}
.dropdown {
  &-menu {
    --bs-dropdown-color: var(--main-color-text);
    --bs-dropdown-bg: var(--bg-second);
    color: var(--main-color-text);
  }

  & > a {
    border: 1px solid var(--bg-btn);
    border-radius: 0.625em;
    padding: 0.7em 1.2em;
    min-height: 45px;
    white-space: break-spaces;
  }
  &-item {
    font-size: 14px;
    padding: 8px 15px;
    color: var(--main-color-text) !important;
    background-color: var(--dropdown-menu) !important;
    &:active,
    &.active {
      color: var(--main-color-text-revers) !important;
      background-color: var(--main-color) !important;
    }
    &:hover:not(.active) {
      color: var(--main-color-text) !important;
      background-color: var(--dropdown-menu-hover) !important;
    }
    white-space: break-spaces;
  }
}
.custom-table {
  background-color: var(--bg-second);
  border-radius: 1em;
  color: var(--main-color-text);
  border: none;
  overflow: hidden;
  &.lite {
    box-shadow: none !important;
  }
  .item {
    border-bottom: 1px solid var(--bg-btn);
    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
    }
  }
  .card-footer:last-child {
    padding: 15px;
    border: none;
    background-color: var(--bg-second);
  }
  .item:last-child {
    border: none;
  }
  .active {
    background-color: var(--bg-btn);
  }
  .desc {
    border-bottom: 1px solid var(--bg-btn);
    .row {
      border: none;
    }
  }
  .col,
  [class^="col-"] {
    padding: 12px;
    align-items: center;
    display: flex;
    font-size: 13px;
  }
  &.lite .head,
  .head {
    background-color: var(--bg-btn);
    color: var(--main-color-text);
    padding: 5px;
    font-weight: 600;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    border-bottom: none;
    .col {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .light {
    background-color: transparent !important;
    border-bottom: 1px solid $gray !important;
  }

  a svg {
    color: var(--main-color-text);
  }
  .form-check-inpu:checked .row {
    background-color: var(--bg-btn);
  }
  .order.status-new {
    background-color: #00a76f14;
  }
  .order.status-reservation {
    background-color: #ab41ff14;
  }
}
.empty {
  svg {
    max-width: 300px;
    max-height: 300px;
    path,
    circle {
      color: var(--main-color) !important;
    }
  }
  &.mini {
    min-height: auto !important;
    svg {
      max-width: 230px;
      max-height: 230px;
    }
  }
}
.bankcard {
  background-color: var(--bg-second);
  border-radius: $radius * 0.5;
  @include shadow();
  padding: $radius * 0.5 $radius * 0.75;
  height: 150px;
  @include flex(column, space-between, stretch, nowrap);
  @media (max-width: $bp-xxl) {
    height: 175px;
  }
  @media (max-width: $bp-xl) {
    height: 150px;
  }
  @media (max-width: $bp-sm) {
    height: 33vw;
  }
  &-logo {
    max-height: 3em;
    max-width: 9em;
    object-fit: scale-down;
    object-position: left center;
    @media (max-width: $bp-xxl) {
      max-height: 4em;
      max-width: 10em;
    }
    @media (max-width: $bp-sm) {
      max-width: 75%;
    }
  }
  &-type {
    width: 2.5em;
    height: 1.4em;
    object-fit: scale-down;
    object-position: left center;
    @media (max-width: $bp-xxl) {
      width: 3em;
      height: 1.5em;
    }
    @media (max-width: $bp-sm) {
      width: 2.75em;
    }
  }
  &-num {
    font-size: 0.95em;
    @media (max-width: $bp-xxl) {
      font-size: 1em;
    }
    @media (max-width: $bp-lg) {
      font-size: 1.1em;
    }
    @media (max-width: $bp-sm) {
      font-size: 1em;
    }
  }
  button {
    font-size: 1.25em;
    color: var(--main-color-text);
  }
}
.time-item-slide {
  max-width: 125px;
}
.time-item {
  background-color: var(--bg-second);
  border-radius: $radius * 0.5;
  border: 2px solid transparent;
  // color: var(--main-color);
  &.active {
    border-color: var(--main-color);
    background-color: var(--main-color-outline);
    color: var(--main-color);
    font-weight: 600;
  }
  &:hover,
  &:active {
    background-color: var(--main-color-outline);
    color: var(--main-color);
  }
  padding: 5px;
  height: 43px;
  @include flex();
  &:disabled,
  &.disabled {
    cursor: default;
    opacity: 0.6;
    background-color: var(--bg-second);
  }
}
.payment {
  user-select: none;
  cursor: pointer;
  background-color: var(--bg-second);
  border-radius: $radius * 0.5;
  border: 2px solid transparent;
  &.active {
    border-color: var(--main-color);
    background-color: var(--main-color-outline);
  }
  padding: $radius * 0.5 $radius * 0.75;
  height: 60px;
  @include flex();
  &-logo {
    max-height: 2em;
    max-width: 2em;
    margin-right: 0.8em;
    object-fit: contain;
  }
  &-num {
    font-weight: 600;
    font-size: 14px;
    @media (max-width: $bp-sm) {
      font-size: 13px;
    }
  }
}
.input-code {
  display: flex;
  input {
    margin-right: 15px;
    text-align: center;
    height: 50px;
    font-size: 20px;
    padding-left: 0px;
    padding-right: 0px;
    font-weight: 600;
    &:last-child {
      margin-right: 0px;
    }
  }
}
.driver-card {
  width: 240px;
  height: 400px;
}
.ymaps-2-1-79-balloon {
  box-shadow: none !important;
}
.ymaps-2-1-79-balloon__tail:after {
  background-color: var(--bg-second) !important;
}
.ymaps-2-1-79-balloon__content {
  padding: 0 !important;
  background: transparent !important;
  margin: 0 !important;
}
.ymaps-2-1-79-balloon__close-button {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTQgLjdsLS43LS43TDcgNi4zLjcgMCAwIC43IDYuMyA3IDAgMTMuM2wuNy43TDcgNy43bDYuMyA2LjMuNy0uN0w3LjcgN3oiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==");
  background-size: contain;
  background-repeat: no-repeat;
  width: 14px !important;
  height: 14px !important;
  top: 13px;
  position: absolute;
  right: 13px;
  fill: #fff !important;
}
.ymaps-2-1-79-balloon__layout {
  background: transparent !important;
  border: none !important;
}

#eHUFWA2qhQM3_tr {
  animation: eHUFWA2qhQM3_tr__tr 3000ms linear infinite normal forwards;
}

@keyframes eHUFWA2qhQM3_tr__tr {
  0% {
    transform: translate(167.405395px, 167.205002px) rotate(0deg);
  }

  60% {
    transform: translate(167.405395px, 167.205002px) rotate(360deg);
  }

  100% {
    transform: translate(167.405395px, 167.205002px) rotate(720deg);
  }
}

#eHUFWA2qhQM4_to {
  animation: eHUFWA2qhQM4_to__to 3000ms linear infinite normal forwards;
}

@keyframes eHUFWA2qhQM4_to__to {
  0% {
    transform: translate(85.855176px, 167.209999px);
  }

  50% {
    transform: translate(85.855176px, 167.209999px);
  }

  60% {
    transform: translate(43.421618px, 167.209999px);
  }

  93.333333% {
    transform: translate(43.421618px, 167.209999px);
  }

  100% {
    transform: translate(85.855176px, 167.209999px);
  }
}

#eHUFWA2qhQM4 {
  animation: eHUFWA2qhQM4_c_o 3000ms linear infinite normal forwards;
}

@keyframes eHUFWA2qhQM4_c_o {
  0% {
    opacity: 1;
  }

  53.333333% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }

  93.333333% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#eHUFWA2qhQM24_to {
  animation: eHUFWA2qhQM24_to__to 3000ms linear infinite normal forwards;
}

@keyframes eHUFWA2qhQM24_to__to {
  0% {
    transform: translate(97.365003px, 248.529999px);
  }

  40% {
    transform: translate(97.365003px, 248.529999px);
  }

  50% {
    transform: translate(80.75px, 288.859107px);
  }

  86.666667% {
    transform: translate(80.75px, 288.859107px);
  }

  93.333333% {
    transform: translate(97.365003px, 248.529999px);
  }

  100% {
    transform: translate(97.365003px, 248.529999px);
  }
}

#eHUFWA2qhQM24 {
  animation: eHUFWA2qhQM24_c_o 3000ms linear infinite normal forwards;
}

@keyframes eHUFWA2qhQM24_c_o {
  0% {
    opacity: 1;
  }

  43.333333% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  86.666667% {
    opacity: 0;
  }

  93.333333% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

#eHUFWA2qhQM40_to {
  animation: eHUFWA2qhQM40_to__to 3000ms linear infinite normal forwards;
}

@keyframes eHUFWA2qhQM40_to__to {
  0% {
    transform: translate(236.795006px, 248.144997px);
  }

  30% {
    transform: translate(236.795006px, 248.144997px);
  }

  40% {
    transform: translate(268.721733px, 280.759994px);
  }

  80% {
    transform: translate(268.721733px, 280.759994px);
  }

  86.666667% {
    transform: translate(236.795006px, 248.144997px);
  }

  100% {
    transform: translate(236.795006px, 248.144997px);
  }
}

#eHUFWA2qhQM40 {
  animation: eHUFWA2qhQM40_c_o 3000ms linear infinite normal forwards;
}

@keyframes eHUFWA2qhQM40_c_o {
  0% {
    opacity: 1;
  }

  33.333333% {
    opacity: 1;
  }

  40% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  86.666667% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

#eHUFWA2qhQM57_to {
  animation: eHUFWA2qhQM57_to__to 3000ms linear infinite normal forwards;
}

@keyframes eHUFWA2qhQM57_to__to {
  0% {
    transform: translate(248.955216px, 167.470001px);
  }

  20% {
    transform: translate(248.955216px, 167.470001px);
  }

  30% {
    transform: translate(291.923206px, 167.470001px);
  }

  73.333333% {
    transform: translate(291.923206px, 167.470001px);
  }

  80% {
    transform: translate(248.955216px, 167.470001px);
  }

  100% {
    transform: translate(248.955216px, 167.470001px);
  }
}

#eHUFWA2qhQM57 {
  animation: eHUFWA2qhQM57_c_o 3000ms linear infinite normal forwards;
}

@keyframes eHUFWA2qhQM57_c_o {
  0% {
    opacity: 1;
  }

  23.333333% {
    opacity: 1;
  }

  30% {
    opacity: 0;
  }

  73.333333% {
    opacity: 0;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

#eHUFWA2qhQM75_to {
  animation: eHUFWA2qhQM75_to__to 3000ms linear infinite normal forwards;
}

@keyframes eHUFWA2qhQM75_to__to {
  0% {
    transform: translate(236.795006px, 86.394996px);
  }

  10% {
    transform: translate(236.795006px, 86.394996px);
  }

  20% {
    transform: translate(254.414741px, 55.705003px);
  }

  66.666667% {
    transform: translate(254.414741px, 55.705003px);
  }

  73.333333% {
    transform: translate(236.795006px, 86.394996px);
  }

  100% {
    transform: translate(236.795006px, 86.394996px);
  }
}

#eHUFWA2qhQM75 {
  animation: eHUFWA2qhQM75_c_o 3000ms linear infinite normal forwards;
}

@keyframes eHUFWA2qhQM75_c_o {
  0% {
    opacity: 1;
  }

  13.333333% {
    opacity: 1;
  }

  20% {
    opacity: 0;
  }

  66.666667% {
    opacity: 0;
  }

  73.333333% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

#eHUFWA2qhQM94_to {
  animation: eHUFWA2qhQM94_to__to 3000ms linear infinite normal forwards;
}

@keyframes eHUFWA2qhQM94_to__to {
  0% {
    transform: translate(97.080004px, 85.900002px);
  }

  10% {
    transform: translate(79.63px, 50.347123px);
  }

  60% {
    transform: translate(79.63px, 50.347123px);
  }

  66.666667% {
    transform: translate(97.080004px, 85.900002px);
  }

  100% {
    transform: translate(97.080004px, 85.900002px);
  }
}

#eHUFWA2qhQM94 {
  animation: eHUFWA2qhQM94_c_o 3000ms linear infinite normal forwards;
}

@keyframes eHUFWA2qhQM94_c_o {
  0% {
    opacity: 1;
  }

  3.333333% {
    opacity: 1;
  }

  10% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  66.666667% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
.my-info {
  padding: 6px;
  padding-bottom: 0px;
}
.my-info-body {
  background-color: var(--bg-second);
  border-radius: 8px;
  color: var(--main-color-text);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  padding: 20px;
  padding-right: 30px;
  padding-top: 30px;
}

.freeDeliveryBar {
  position: fixed;
  z-index: 1;
  bottom: 1em;
  right: 1em;
  width: 80px;
  font-size: 11px;
  text-align: center;
  // font-family: "Lobster", sans-serif;
  @media (max-width: $bp-lg) {
    bottom: calc(var(--f-height) - 2px);
    width: 100%;
    height: var(--f-height);
    right: 0px;
    background-color: var(--bg-main);
  }
  .box {
    max-width: 330px;
    height: 100%;
    margin: 0px auto;
    text-align: left;
    @include flex(column, center, stretch, nowrap);
    @media (max-width: $bp-sm) {
      max-width: 300px;
    }
  }
  .mobileBar {
    height: 40px;
    margin-top: -10px;
    @include flex();
    position: relative;
    .bar {
      height: 8px;
      width: 100%;
      border-radius: 30px;
      background-color: var(--bg-second);
      position: relative;
      z-index: 1;
      &[data-state="half"] {
        &::after {
          content: "";
          position: absolute;
          top: 0px;
          left: 0px;
          height: 100%;
          width: 55%;
          border-radius: 30px;
          background: linear-gradient(to right, #038036 9%, #02b54c 90%);
        }
      }
      &[data-state="full"] {
        &::after {
          content: "";
          position: absolute;
          top: 0px;
          left: 0px;
          height: 100%;
          width: 100%;
          border-radius: 5px;
          background: linear-gradient(to right, #038036 9%, #02b54c 90%);
        }
        & + img {
          background-color: #02b54c;
        }
      }
    }
    img {
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 10;
      width: 30px;
      height: 40px;
      object-fit: contain;
      border-radius: 50%;
      background-color: var(--bg-second);
      padding: 4px;
    }
  }
}
.white-space-break {
  white-space: break-spaces;
}
.mb-6 {
  margin-bottom: 4em;
}
.article {
  &-card {
    img {
      border-radius: $radius;
      height: 220px;
      object-fit: cover;
    }
    figcaption {
      @include clamp(3, 1.3em, true);
    }
  }
  &-page {
    img {
      border-radius: $radius;
      height: 500px;
      max-width: 100%;
      object-fit: contain;
    }
  }
  &-preview {
    display: flex;
    flex-direction: row;
    img {
      width: 180px;
      height: 180px;
      object-fit: cover;
      border-radius: $radius;
      @media (max-width: $bp-sm) {
        width: 100px;
        height: 100px;
        margin-right: 15px;
      }
    }
  }
}
.blog-post-background {
  border-radius: $radius;
  overflow: hidden;
  background-size: cover;
  filter: blur(100px);
  position: absolute; /* Размещает фон в абсолютных координатах */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Помещает фон под изображение */
}
.bg-main {
  background-color: var(--bg-main) !important;
}
.close,
.btn-close {
  filter: none !important;
  color: var(--main-color-text);
  z-index: 99999;
  display: flex;
  position: absolute !important;
  top: 0px !important;
  right: -50px !important;
  padding: 0px !important;
  box-shadow: none !important;
  font-size: 14px !important;
  background-color: var(--bg-main) !important;
  border-radius: 40px !important;
  align-items: center;
  justify-content: center;
  margin: 0px !important;
  width: 40px !important;
  height: 40px !important;
  @include flex();
  opacity: 0.8;
  transition: opacity $transition;
  &:hover,
  &:focus,
  &:active {
    opacity: 1;
    box-shadow: none;
    color: var(--main-color-text);
    outline: none;
  }
  @media (max-width: $bp-sm) {
    right: 10px !important;
    top: 7px !important;
    background-color: transparent;
  }
}
.status {
  border-radius: 30px;
  padding: 0px 10px;
  height: 33px;
  font-size: 13px;
  vertical-align: middle;
  display: flex;
  color: #fff;
  align-items: center;
  .img,
  svg {
    width: 33px;
    height: 33px;
    display: block;
    padding: 2px;
    border-radius: 30px;
    margin-right: 3px;
    stroke: #fff;
    color: #fff;
    path,
    circle {
      stroke: #fff;
      color: #fff;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 30px;
    }
  }
}
.brand-item {
  padding: 0.9em;
  display: block;
  border-radius: $radius;
  &.active {
    background-color: var(--bg-second-hover);
  }
  &:hover {
    background-color: var(--bg-second);
  }
  .logo {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100%;
  }
}
.card {
  background-color: var(--bg-card);
  color: var(--main-color-text);
}
.popover {
  --bs-popover-bg: var(--bg-second);
  --bs-popover-border-color: var(--bg-second-hover);
  --bs-popover-header-color: var(--main-color-text);
  --bs-popover-header-bg: var(--bg-second-hover);
  --bs-popover-body-color: var(--main-color-text);
  .popover-arrow {
    --bs-popover-bg: var(--bg-second-hover);
  }
}
.tag {
  display: inline-flex;
  margin-right: 10px;
  img {
    width: 35px;
    height: 35px;
    border: 2px solid transparent;
    box-shadow: 0px 0px 0px 2px #00a76f;
    object-fit: cover;
    border-radius: 100%;
  }
  &.mini {
    margin-right: 4px;
    img {
      width: 35px;
      height: 35px;
      box-shadow: none;
    }
  }
}
.text-muted {
  color: #777 !important;
}
.text-main {
  color: var(--main-color);
  &:hover {
    opacity: 0.8;
    color: var(--main-color);
  }
}
.cities {
  columns: 3;
  overflow: hidden;
  @media (max-width: $bp-sm) {
    columns: 1;
  }
}
.cities-box {
  padding-left: 25px;
  margin-bottom: 15px;
  position: relative;
  page-break-inside: avoid;
  max-width: 100%;
  break-inside: avoid;
  backface-visibility: hidden;
  .cities-box-letter {
    position: absolute;
    left: 7px;
    top: 0px;
  }
  @media (max-width: $bp-sm) {
    font-size: 19px !important;
    padding-left: 35px;
  }
}
.search-box {
  padding-top: 20px;
  position: relative;
  max-height: 50vh;
  @include scrollCustomized();
  overflow: auto;
  @media (max-width: $bp-sm) {
    max-height: 100% !important;
    h6 {
      font-size: 20px !important;
    }
  }
}
.box-shadow {
  position: relative;
  .box-shadow-top {
    display: block;
    content: "";
    top: -1px;
    right: 0px;
    left: 0px;
    z-index: 100;
    position: absolute;
    height: 35px;
    background: var(--box-shadow-top);
  }
  .box-shadow-bottom {
    position: absolute;
    bottom: -1px;
    left: 0px;
    right: 0px;
    height: 35px;
    z-index: 100;
    transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
    background: var(--box-shadow-bottom);
  }
}
.white-space {
  white-space: pre-wrap !important;
}
.text-light-muted {
  color: var(--color-light-gray) !important;
}
.pagination-mouse {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0;
  z-index: 9999;
  display: flex;
  flex-direction: row;
}
.pagination-mouse-item {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 5px;
  background-color: transparent;
  &::before {
    position: absolute;
    content: "";
    left: 0px;
    border-radius: 5px;
    margin: 0px 5px;
    margin-bottom: 10px;
    right: 0px;
    z-index: 9999;
    height: 3px;
    background-color: rgba(255, 255, 255, 0.1) !important;
    bottom: 0px;
  }
}
.pagination-mouse-item-active::before {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

img.hide {
  display: none;
}
img.show {
  display: flex;
}

.banner-slider {
  overflow: hidden !important;
  .slick-slide > div {
    cursor: grab;
    padding: 0px 10px;
    img {
      width: 100%;
      border-radius: $radius;
      height: 370px;
      object-fit: cover;
      @media (max-width: $bp-xxl) {
        height: 320px;
      }
      @media (max-width: $bp-lg) {
        height: 275px;
      }
      @media (max-width: $bp-md) {
        height: 225px;
      }
      @media (max-width: $bp-sm) {
        height: 230px;
      }
      @media (max-width: $bp-xs) {
        height: 195px;
      }
      @media (max-width: $bp-xxs) {
        height: 160px;
      }
    }
    @media (max-width: $bp-sm) {
      padding: 0px 5px;
      img {
        border-radius: 12px;
      }
    }
    .big {
      height: 520px;
      @media (max-width: $bp-xxl) {
        height: 420px;
      }
      @media (max-width: $bp-lg) {
        height: 280px;
      }
      @media (max-width: $bp-md) {
        height: 220px;
      }
      @media (max-width: $bp-sm) {
        height: 200px;
      }
    }
  }
  .slick-dots {
    bottom: 0px !important;
    position: relative !important;
  }
  .slick-dots li button:before {
    color: var(--main-color) !important;
  }
}
:focus-visible {
  outline: none !important;
}
.rounded-4 {
  border-radius: 0.4rem !important;
}
.rounded-5 {
  border-radius: 0.5rem !important;
}
.rounded-6 {
  border-radius: 0.6rem !important;
}
.rounded-7 {
  border-radius: 0.7rem !important;
}
.rounded-8 {
  border-radius: 0.8rem !important;
}
.rounded-9 {
  border-radius: 0.9rem !important;
}
.rounded-10 {
  border-radius: 1rem !important;
}
.rounded-11 {
  border-radius: 1.1rem !important;
}
.btn-lg {
  min-height: 48px !important;
  font-size: 1rem !important;
}
.bg-danger {
  background-color: #ff4157 !important;
}
.top-h {
  top: calc(var(--h-height) + 40px) !important;
}
.list-group {
  --bs-list-group-color: var(--main-color-text);
  --bs-list-group-bg: var(--bg-second);
  --bs-list-group-border-color: var(--bg-second-hover);
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--main-color-text);
  --bs-list-group-action-hover-bg: var(--bg-second-hover);
  --bs-list-group-action-active-color: var(--main-color-text);
  --bs-list-group-action-active-bg: var(--bg-second-hover);
  --bs-list-group-disabled-color: var(--main-color-text);
  --bs-list-group-disabled-bg: var(--bg-second-hover);
}
.color-main {
  color: var(--main-color);
  &:hover {
    color: var(--main-color);
  }
}
.input-date {
  cursor: pointer;
  user-select: none;
  &::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    cursor: pointer;
    opacity: 0.6;
  }
}
.input-time {
  font-size: 15px !important;
  max-width: 110px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  padding-left: 15px !important;
  height: 45px !important;
  padding-right: 6px !important;
  &::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    cursor: pointer;
    opacity: 0.6;
  }
}
.login {
  position: relative;
  &-box {
    width: 100%;
  }
  &-box-qr {
    width: 100%;
    padding: 15px;
    .qr-login {
      background-color: #fff;
      padding: 25px;
      border-radius: 25px;
      height: auto;
      max-width: 70%;
      width: 70%;
    }
  }
  form {
    background-color: var(--bg-second);
    border-radius: $radius;
    padding: 2em;
    width: 100%;
    margin: 0 auto;
    @media (max-width: $bp-lg) {
      width: 100%;
      padding: 1.3em;
    }
  }

  &-toggler {
    position: absolute;
    top: 60px;
    right: 0%;
    bottom: 0px;
    z-index: 10;
    width: 38%;
    border-radius: 30px;
    background-color: var(--main-color);
    background-image: url(imgs/bg/entrance.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 50%;
    color: #fff;
    text-align: center;
    padding-top: 4em;
    .btn-40 {
      width: 40%;
    }
    .text {
      position: relative;
      overflow: hidden;
      color: #fff;
      &-1 {
        position: relative;
        z-index: 1;
        width: 100%;
        margin-left: 0%;
      }
      &-2 {
        @include absBG(10, 0px, 100%);
        left: -100%;
      }
    }
  }
  &-mobile {
    display: block;
    padding: 2em 0px;
  }
}
.time-container {
  position: relative;
  overflow: hidden;
  user-select: none;
}
.badge-sm {
  padding: 0.2em 0.55em !important;
  font-size: 0.7em !important;
  align-items: center;
  display: flex;
  justify-content: center;
  align-self: center;
  line-height: 14px;
}
.badge-main {
  background-color: var(--main-color) !important;
  &-light {
    background-color: var(--main-color-outline) !important;
    color: var(--main-color);
  }
}
.modal-header {
  border-bottom-color: var(--bg-second) !important;
}
.modal-footer {
  border-top-color: var(--bg-second) !important;
}
.zone {
  &:hover {
    font-weight: 600 !important;
  }
}
.btn-close-fixed {
  position: fixed;
  right: 0px;
  z-index: 9999;
  outline: none !important;
  box-shadow: none !important;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0px !important;
  margin: 0px !important;
}
.user-select {
  user-select: none !important;
}
.affiliates-list-modal {
  height: 450px !important;
  overflow: auto;
  @media (max-width: $bp-sm) {
    height: auto !important;
    overflow: hidden;
  }
}
.z-1000 {
  z-index: 1000 !important;
}
.top-minus-3 {
  top: -1rem !important;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out !important;
}
.cookie {
  position: fixed;
  z-index: 9999;
  right: 10px;
  bottom: 10px;
  max-width: 370px;
  background: var(--bg-main);
  padding: 17px;
  border-radius: 15px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.modal-app {
  .modal-content {
    border-radius: 40px !important;
    padding: 20px !important;
    .btn-close {
      top: 15px !important;
      right: 20px !important;
      width: 50px !important;
      height: 50px !important;
      background-color: var(--bg-second) !important;
      // color: var(--main-color-text) !important;
      opacity: 1 !important;
      font-size: 18px !important;
    }
  }
}
.header-delivery {
  border: 2px solid rgba(0, 0, 0, 0.08);
  height: 45px;
  border-radius: 10px;
  padding: 0px 10px;
  max-width: 200px !important;
}
.clamp-1 {
  @include clamp(1, 1.2em, true);
}
.delivery-footer {
  background-color: var(--bg-header-active) !important;
}
.disabled {
  cursor: no-drop !important;
  opacity: 0.6 !important;
  label,
  input {
    cursor: no-drop !important;
  }
}
.max-width-700 {
  max-width: 700px !important;
}
